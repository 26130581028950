import React, { useState } from 'react';
import { RiCloseCircleLine } from "react-icons/ri";
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useStateContext } from '../contexts/ContextProvider';

const NavButton = ({ title, customFunc, icon, color, size = 'xl', padding = 'p-3' }) => (
  <TooltipComponent content={title} position="BottomCenter">
    <button
      type="button"
      onClick={customFunc}
      style={{ color }}
      className={`relative text-${size} rounded-full ${padding} hover:bg-light-gray`}
    >
      {icon}
    </button>
  </TooltipComponent>
);

const DetalleClientes = ({ onClose, cliente }) => {
  const { currentColor } = useStateContext();
  const [isEditing, setIsEditing] = useState(false);
  const [clienteData, setClienteData] = useState(cliente); // Inicializamos el estado con el cliente seleccionado

  const handleEditClick = () => {
    setIsEditing(true); // Activar modo edición
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setClienteData({ ...clienteData, [name]: value }); // Actualizamos los datos del cliente en el estado
  };

  const handleSaveClick = () => {
    // Aquí iría la lógica para guardar los cambios, como una solicitud PUT a la API
    console.log("Datos guardados:", clienteData);
    setIsEditing(false); // Desactivar el modo edición al guardar
  };

  return (
    <>
      <div className="bg-half-transparent w-screen fixed nav-item top-0 right-0 h-full flex">
        <div className="responsive-div p-6" style={{ backgroundColor: 'white', borderRadius: '12px', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', width: '50%', maxWidth: 'none', height: '100%', borderLeft: '1px solid #ddd', boxShadow: '-4px 0 10px rgba(0, 0, 0, 0.1)', display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
          <div className="flex justify-between items-center mb-6">
            <p className="font-semibold text-lg" style={{ color: '#FFa800' }}>Detalle Cliente</p>
            <NavButton
              title="Cerrar"
              customFunc={onClose}
              color={currentColor}
              icon={<RiCloseCircleLine />}
              size="3xl"
              padding="p-4"
            />
          </div>

          <header>
            <div className="logo">
              <img src="./images/logo.png" alt="Logo" />
            </div>
            <div className="yellow-bar"></div>
          </header>

          <div className="cotizacion-header">
            <div className="yellow-bar-left"></div>
            <h1 className="cotizacion-title">CLIENTE</h1>
            <div className="yellow-bar-right"></div>
          </div>

          <div className="cliente-info">
            <div className="left-info">
              <p><strong>Nombre:</strong></p>
              {isEditing ? (
                <input
                  type="text"
                  name="nombre"
                  value={clienteData.nombre}
                  onChange={handleInputChange}
                  className="border p-2 rounded-lg"
                />
              ) : (
                <p>{clienteData.nombre}</p>
              )}
              <p><strong>Apellido:</strong></p>
              {isEditing ? (
                <input
                  type="text"
                  name="apellido"
                  value={clienteData.apellido}
                  onChange={handleInputChange}
                  className="border p-2 rounded-lg"
                />
              ) : (
                <p>{clienteData.apellido}</p>
              )}
              <p><strong>Región:</strong></p>
              {isEditing ? (
                <input
                  type="text"
                  name="region"
                  value={clienteData.region}
                  onChange={handleInputChange}
                  className="border p-2 rounded-lg"
                />
              ) : (
                <p>{clienteData.region}</p>
              )}
              <p><strong>Comuna:</strong></p>
              {isEditing ? (
                <input
                  type="text"
                  name="comuna"
                  value={clienteData.comuna}
                  onChange={handleInputChange}
                  className="border p-2 rounded-lg"
                />
              ) : (
                <p>{clienteData.comuna}</p>
              )}
            </div>
            <div className="right-info">
              <p><strong>Email:</strong></p>
              {isEditing ? (
                <input
                  type="email"
                  name="email"
                  value={clienteData.email}
                  onChange={handleInputChange}
                  className="border p-2 rounded-lg"
                />
              ) : (
                <p>{clienteData.email}</p>
              )}
              <p><strong>WhatsApp:</strong></p>
              {isEditing ? (
                <input
                  type="text"
                  name="whatsapp"
                  value={clienteData.whatsapp}
                  onChange={handleInputChange}
                  className="border p-2 rounded-lg"
                />
              ) : (
                <p>{clienteData.whatsapp}</p>
              )}
              <p><strong>Dirección:</strong></p>
              {isEditing ? (
                <input
                  type="text"
                  name="direccion"
                  value={clienteData.direccion}
                  onChange={handleInputChange}
                  className="border p-2 rounded-lg"
                />
              ) : (
                <p>{clienteData.direccion}</p>
              )}
              <p><strong>Notas:</strong></p>
              {isEditing ? (
                <textarea
                  name="notas"
                  value={clienteData.notas}
                  onChange={handleInputChange}
                  className="border p-2 rounded-lg w-full"
                />
              ) : (
                <p>{clienteData.notas}</p>
              )}
            </div>
          </div>

          <div className="flex justify-between mt-4">
            <button
              type="button"
              className="bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600 transition duration-200"
              onClick={handleEditClick}
            >
              Editar
            </button>
            <div className="flex">
              <button
                type="button"
                className="bg-yellow-500 text-white p-2 rounded-lg hover:bg-yellow-600 transition duration-200 mr-4"
              >
                Descargar PDF
              </button>
              {isEditing && (
                <button
                  type="button"
                  className="bg-green-500 text-white p-2 rounded-lg hover:bg-green-600 transition duration-200"
                  onClick={handleSaveClick}
                >
                  Guardar
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetalleClientes;
