import React, { useState } from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Inject, Sort, Filter } from '@syncfusion/ej2-react-grids';
import { RiCloseCircleLine } from "react-icons/ri";
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useStateContext } from '../contexts/ContextProvider';
import axios from 'axios';

const NavButton = ({ title, customFunc, icon, color, size = 'xl', padding = 'p-3' }) => (
  <TooltipComponent content={title} position="BottomCenter">
    <button
      type="button"
      onClick={customFunc}
      style={{ color }}
      className={`relative text-${size} rounded-full ${padding} hover:bg-light-gray`}
    >
      {icon}
    </button>
  </TooltipComponent>
);

const DetalleStock = ({ onClose, stock = {}, categoria }) => {
  const { currentColor } = useStateContext();
  const [recargasHistorico, setRecargasHistorico] = useState([]);
  const [formVisible, setFormVisible] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editStock, setEditStock] = useState(stock.unidades || ''); 
  const [editComment, setEditComment] = useState('');
  const [stockAsignar, setStockAsignar] = useState(''); // Para el valor de asignación de stock
  const [asignarVisible, setAsignarVisible] = useState(false); // Para controlar la visibilidad del campo de asignar stock

  const handleStockEditChange = (e) => setEditStock(e.target.value);
  const handleEditCommentChange = (e) => setEditComment(e.target.value);
  const handleStockAsignarChange = (e) => setStockAsignar(e.target.value);

  // Función para manejar el registro de una modificación de stock
  const registrarStockEnHistorico = async (codigo, cantidad, proveedor, comentarios) => {
    const token = localStorage.getItem('access_token'); // Obtener token JWT si lo tienes

    try {
      await axios.post('http://localhost:8000/historico_stock/registrar/', {
        codigo,
        cantidad,
        proveedor,
        comentarios,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Asegurarse de incluir el token si es necesario
        }
      });
    } catch (error) {
      console.error('Error registrando en el historial de stock:', error);
    }
  };

  // Función para manejar la edición de stock (Modificación manual)
  const handleSaveEdit = async () => {
    if (editComment.trim() === '') {
      alert('Por favor, agrega un comentario para justificar la modificación del stock.');
      return;
    }
    try {
      // Update en el stock
      await axios.put(`http://localhost:8000/api/laminas/${stock.codigo}/`, {
        unidades: editStock,
      });

      // Registrar en histórico
      registrarStockEnHistorico(stock.codigo, editStock, 'Modificación manual', 'Ajuste manual');

      setRecargasHistorico([
        ...recargasHistorico,
        {
          Fecha: new Date().toISOString().split('T')[0],
          Cantidad: editStock,
          Proveedor: 'Manual',
          Comentarios: editComment,
        },
      ]);
      setEditMode(false);
      setEditComment('');
    } catch (error) {
      console.error('Error guardando el stock editado:', error);
    }
  };

  // Función para manejar la asignación de stock
  const handleAssignStock = async () => {
    if (!stockAsignar || stockAsignar <= 0 || stockAsignar > stock.unidades) {
      alert('La cantidad a asignar no es válida.');
      return;
    }
    try {
      // Asignación de láminas a stock de auto
      await axios.post(`http://localhost:8000/api/laminas/asignar-stock/`, {
        codigo_bodega: stock.codigo,
        cantidad_asignada: stockAsignar,
      });

      // Actualizar los valores del frontend
      setRecargasHistorico([
        ...recargasHistorico,
        {
          Fecha: new Date().toISOString().split('T')[0],
          Cantidad: stockAsignar,
          Proveedor: 'Asignación a auto',
          Comentarios: 'Asignación de stock a láminas auto',
        },
      ]);
      alert('Stock asignado con éxito');
      setAsignarVisible(false); // Ocultar el campo de asignación después de asignar
    } catch (error) {
      console.error('Error asignando stock:', error);
    }
  };

  // Función para manejar la recarga de stock (Agregar Stock)
  const handleAddStock = async () => {
    if (editComment.trim() === '') {
      alert('Por favor, agrega un comentario y un proveedor para la recarga de stock.');
      return;
    }
    try {
      // Update en el stock
      await axios.put(`http://localhost:8000/api/laminas/${stock.codigo}/`, {
        unidades: editStock,
      });

      // Registrar en histórico
      registrarStockEnHistorico(stock.codigo, editStock, 'Proveedor', 'Recarga stock');

      setRecargasHistorico([
        ...recargasHistorico,
        {
          Fecha: new Date().toISOString().split('T')[0],
          Cantidad: editStock,
          Proveedor: 'Proveedor',
          Comentarios: editComment,
        },
      ]);
      setFormVisible(false);
    } catch (error) {
      console.error('Error recargando el stock:', error);
    }
  };

  // Renderizar detalles del stock por categoría
  const renderStockDetails = () => {
    if (!stock || Object.keys(stock).length === 0) {
      return <p>No hay información del stock disponible.</p>;
    }
    switch (categoria) {
      case 'lamina':
        return (
          <>
            <div className="left-info">
              <p><strong>Código:</strong> {stock.codigo || 'N/A'}</p>
              <p><strong>Nombre:</strong> {stock.nombre || 'N/A'}</p>
              <p><strong>Descripción:</strong> {stock.descripcion || 'N/A'}</p>
              <p><strong>Tipo Stock:</strong> {stock.tipo_stock || 'N/A'}</p>
            </div>
            <div className="right-info">
              <p><strong>Rendimiento:</strong> {stock.rendimiento || 'N/A'} m²</p>
              <p><strong>Precio m²:</strong> {stock.preciom2 || 'N/A'}</p>
              <p><strong>Unidades:</strong> {editMode ? (
                <input
                  type="number"
                  value={editStock}
                  onChange={handleStockEditChange}
                  className="border p-2 rounded-lg"
                />
              ) : (
                stock.unidades || 'N/A'
              )}
              </p>
            </div>
          </>
        );
      // Similar para herramienta y vehiculo
      default:
        return <p>No hay detalles disponibles para esta categoría.</p>;
    }
  };

  return (
    <div className="bg-half-transparent w-screen fixed nav-item top-0 right-0 h-full flex">
      <div className="responsive-div p-6" style={{ backgroundColor: 'white', borderRadius: '12px', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', width: '50%', height: '100%', display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
        <div className="flex justify-between items-center mb-6">
          <p className="font-semibold text-lg" style={{ color: '#FFa800' }}>Detalle de Stock</p>
          <NavButton
            title="Cerrar"
            customFunc={onClose}
            color={currentColor}
            icon={<RiCloseCircleLine />}
            size="3xl"
            padding="p-4"
          />
        </div>

        <div className="cotizacion-header">
          <h1 className="cotizacion-title">DETALLE STOCK</h1>
        </div>

        <div className="cliente-info">
          {renderStockDetails()}
        </div>

        {/* Grilla del historial de recargas */}
        <div className="mt-6">
          <h2 className="font-semibold text-lg" style={{ color: '#FFa800' }}>Histórico de Recargas</h2>
          <GridComponent
            dataSource={recargasHistorico}
            allowPaging={true}
            pageSettings={{ pageCount: 5 }}
            allowSorting={true}
            allowFiltering={true}
          >
            <ColumnsDirective>
              <ColumnDirective field="Fecha" headerText="Fecha" width="100" textAlign="Center" />
              <ColumnDirective field="Cantidad" headerText="Cantidad" width="100" textAlign="Center" />
              <ColumnDirective field="Proveedor" headerText="Proveedor" width="150" textAlign="Center" />
              <ColumnDirective field="Comentarios" headerText="Comentarios" width="200" textAlign="Center" />
            </ColumnsDirective>
            <Inject services={[Page, Sort, Filter]} />
          </GridComponent>
        </div>

        {/* Botones para agregar stock o editar */}
        <div className="flex justify-between mt-4">
          {!editMode && (
            <button
              type="button"
              className="bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600 transition duration-200 mr-4"
              onClick={() => setFormVisible(!formVisible)}
            >
              {formVisible ? 'Cancelar' : 'Agregar Stock'}
            </button>
          )}
          <button
            type="button"
            className="bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600 transition duration-200"
            onClick={() => setEditMode(!editMode)}
          >
            {editMode ? 'Cancelar Edición' : 'Editar Stock'}
          </button>
          {editMode && (
            <button
              type="button"
              className="bg-green-500 text-white p-2 rounded-lg hover:bg-green-600 transition duration-200"
              onClick={handleSaveEdit}
            >
              Guardar
            </button>
          )}
          {categoria === 'lamina' && stock.tipo_stock === 'bodega' && (
            <>
              <button
                type="button"
                className="bg-red-500 text-white p-2 rounded-lg hover:bg-red-600 transition duration-200 ml-4"
                onClick={() => setAsignarVisible(!asignarVisible)}
              >
                Asignar Láminas
              </button>
              {asignarVisible && (
                <>
                  <input
                    type="number"
                    className="border p-2 rounded-lg"
                    placeholder="Cantidad a asignar"
                    value={stockAsignar}
                    onChange={handleStockAsignarChange}
                  />
                  <button
                    type="button"
                    className="bg-green-500 text-white p-2 rounded-lg hover:bg-green-600 transition duration-200"
                    onClick={handleAssignStock}
                  >
                    OK
                  </button>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DetalleStock;
