import React, { useState, useEffect } from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Selection, Inject, Edit, Toolbar, Sort, Filter } from '@syncfusion/ej2-react-grids';
import { OrdenesData, OrdenGrid } from '../data/dummy';
import { FaDownload, FaPrint } from 'react-icons/fa';
import { Orden, Header, DetalleOrdenVenta } from '../components';
import { Tabs, Tab } from '@mui/material';

const customerGridImage = (props) => {
  return <span>{props.CustomerName}</span>;
};

const actionTemplate = (props) => {
  return (
    <div className="flex items-center space-x-2">
      {/* Botón de descarga */}
      <button
        type="button"
        title="Descargar"
        style={{ backgroundColor: '#FFa800', color: 'white' }}
        className="p-2 rounded flex items-center space-x-2"
        onClick={() =>
          alert(`📄 Cotización de cliente ${props.CustomerID} se está descargando en formato PDF.`)
        }
      >
        <FaDownload className="h-5 w-5" />
      </button>

      {/* Botón de impresión */}
      <button
        type="button"
        title="Imprimir"
        style={{ backgroundColor: '#FFa800', color: 'white' }}
        className="p-2 rounded flex items-center space-x-2"
        onClick={() =>
          alert(`🖨️ Cotización de cliente ${props.CustomerID} se está enviando a la impresora.`)
        }
      >
        <FaPrint className="h-5 w-5" />
      </button>
    </div>
  );
};

const asignarButtonTemplate = (props) => {
  return (
    !props.asignacion ? 
    <button
      type="button"
      style={{ backgroundColor: '#FFa800', color: 'white' }}
      className="p-2 rounded flex items-center space-x-2"
      onClick={() =>
        alert(`📄 Boton para asignar orden de venta ${props.CustomerID}.`)
      }
    >
      Asignar
    </button> : 
    props.asignacion
  );
};

const Ordenes = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [filteredData, setFilteredData] = useState(OrdenesData);
  const [ordenVisible, setOrdenVisible] = useState(false);
  const [detalleOrdenVisible, setDetalleOrdenVisible] = useState(false);
  const [selectedOrden, setSelectedOrden] = useState(null);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    filterData(selectedTab);
  }, [selectedTab]);

  const filterData = (tabIndex) => {
    let newData = OrdenesData;

    switch (tabIndex) {
      case 0:
        newData = OrdenesData.filter(item => item.Estado === 'Pendiente Asignar');
        break;
      case 1:
        newData = OrdenesData.filter(item => item.Estado === 'Pendiente Enviar');
        break;
      case 2:
        newData = OrdenesData.filter(item => item.Estado === 'Asignada');
        break;
      case 3:
        newData = OrdenesData.filter(item => item.Estado === 'Enviada');
        break;
      case 4:
        newData = OrdenesData; // Muestra todas las órdenes
        break;
      default:
        newData = OrdenesData;
    }

    setFilteredData(newData);
  };

  const handleNewOrdenClick = () => {
    setOrdenVisible(true); // Mostrar el formulario de nueva orden de venta
  };

  const handleRowDoubleClick = (args) => {
    setSelectedOrden(args.rowData); // Guarda la orden seleccionada
    setDetalleOrdenVisible(true); // Mostrar el formulario de cotización al hacer doble clic en una fila
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header title="Órdenes de Venta" />
      
      <div className="flex justify-between items-center mb-4">
        <Tabs value={selectedTab} onChange={handleTabChange} aria-label="basic tabs example"
          sx={{
            '.MuiTab-root': {
              '&:hover': {
                color: '#FFa800', // Cambia el color del texto en hover
              },
              '&.Mui-selected': {
                color: '#FFa800', // Cambia el color del texto cuando la pestaña está seleccionada
              },
            },
            '.MuiTabs-indicator': {
              backgroundColor: '#FFa800', // Cambia el color del subrayado en la pestaña activa
            },
          }}
        >
          <Tab label="Por Asignar" />
          <Tab label="Por Enviar" />
          <Tab label="Asignada" />
          <Tab label="Enviada" />
          <Tab label="Todas las órdenes" />
        </Tabs>
        <button
          type="button"
          onClick={handleNewOrdenClick}
          style={{
            backgroundColor: '#1fb519',  // Verde personalizable
            color: 'white',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            padding: '8px 16px',
            borderRadius: '8px',
            fontWeight: 'lighter',
            textTransform: 'none',
            border: 'none', // Elimina cualquier borde predeterminado
            cursor: 'pointer', // Cambia el cursor al pasar sobre el botón
          }}
        >
          + Nueva Orden de Venta
        </button>
      </div>

      {/* Renderizar formulario de nueva orden */}
      {ordenVisible && (
        <Orden
          onClose={() => setOrdenVisible(false)} // Pasar la función onClose a Orden
        />
      )}

      {detalleOrdenVisible && selectedOrden && (
        <DetalleOrdenVenta
          onClose={() => setDetalleOrdenVisible(false)} // Pasar la función onClose a DetalleOrdenVenta
          orden={selectedOrden} // Pasar la orden seleccionada
        />
      )}

      {/* Grilla de órdenes de venta existentes */}
      <GridComponent
        dataSource={filteredData}
        enableHover={false}
        allowPaging
        pageSettings={{ pageCount: 5 }}
        selectionSettings={{ persistSelection: true }}
        toolbar={[]}
        editSettings={{ allowDeleting: true, allowEditing: true }}
        allowSorting
        className="mt-4"
        recordDoubleClick={handleRowDoubleClick}
      >
        <ColumnsDirective>
          {OrdenGrid.map((item, index) => {
            if (item.headerText === 'Nombre') {
              return (
                <ColumnDirective
                  key={index}
                  {...item}
                  template={customerGridImage} // Usar template para mostrar el nombre
                />
              );
            } else if (item.headerText === 'PDF') {
              return (
                <ColumnDirective
                  key={index}
                  {...item}
                  template={actionTemplate} // Usar template para el botón PDF
                />
              );
            } else {
              return (
                <ColumnDirective
                  key={index}
                  {...item}
                />
              );
            }
          })}
        </ColumnsDirective>
        <Inject services={[Page, Selection, Toolbar, Edit, Sort, Filter]} />
      </GridComponent>
    </div>
  );
};

export default Ordenes;
