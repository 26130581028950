import React, { useState } from 'react';
import { RiCloseCircleLine } from "react-icons/ri";
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useStateContext } from '../contexts/ContextProvider';

const NavButton = ({ title, customFunc, icon, color }) => (
  <TooltipComponent content={title} position="BottomCenter">
    <button
      type="button"
      onClick={customFunc}
      style={{ color }}
      className="relative text-xl rounded-full p-3 hover:bg-light-gray"
    >
      {icon}
    </button>
  </TooltipComponent>
);

const AgregarCuentaPorPagar = ({ onClose }) => {
  const { currentColor } = useStateContext();
  const [showConfirm, setShowConfirm] = useState(false);

  const [cuentaPorPagarDetails, setCuentaPorPagarDetails] = useState({
    proveedor: '',
    monto: '',
    fechaVencimiento: '',
    estado: 'Pendiente',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCuentaPorPagarDetails({ ...cuentaPorPagarDetails, [name]: value });
  };

  const handleGuardarClick = (e) => {
    e.preventDefault();
    setShowConfirm(true);
    // Aquí puedes agregar la lógica para guardar la nueva cuenta por pagar
  };

  return (
    <>
      <div className="bg-half-transparent w-screen fixed nav-item top-0 right-0">
        <div className="responsive-div" style={{ backgroundColor: 'white' }}>
          <div className="flex justify-between items-center mb-4">
            <p className="font-semibold text-lg" style={{ color: '#FFa800' }}>Nueva Cuenta por Pagar</p>
            <NavButton
              title="Cerrar"
              customFunc={onClose}
              color={currentColor}
              icon={<RiCloseCircleLine />}
            />
          </div>

          <div className="mb-4">
            <p className="font-semibold text-lg">Detalles de la Cuenta por Pagar</p>
          </div>

          <div
            className="overflow-y-auto"
            style={{
              maxHeight: '80vh',
            }}
          >
            {/* Formulario para ingresar los detalles de la cuenta por pagar */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
              <div>
                <label className="block text-gray-700">Proveedor</label>
                <input
                  type="text"
                  name="proveedor"
                  className="w-full p-2 border rounded"
                  placeholder="Nombre del Proveedor"
                  value={cuentaPorPagarDetails.proveedor}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className="block text-gray-700">Monto</label>
                <input
                  type="text"
                  name="monto"
                  className="w-full p-2 border rounded"
                  placeholder="Monto de la Cuenta por Pagar"
                  value={cuentaPorPagarDetails.monto}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className="block text-gray-700">Fecha de Vencimiento</label>
                <input
                  type="date"
                  name="fechaVencimiento"
                  className="w-full p-2 border rounded"
                  value={cuentaPorPagarDetails.fechaVencimiento}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className="block text-gray-700">Estado</label>
                <select
                  name="estado"
                  className="w-full p-2 border rounded"
                  value={cuentaPorPagarDetails.estado}
                  onChange={handleInputChange}
                >
                  <option value="Pendiente">Pendiente</option>
                  <option value="Pagado">Pagado</option>
                </select>
              </div>
            </div>

            <div className="flex justify-between mt-4">
              <button
                type="button"
                className="bg-gray-300 p-2 rounded text-gray-700"
                onClick={onClose}
              >
                Cerrar
              </button>
              <button
                type="submit"
                className="p-2 rounded text-white"
                onClick={handleGuardarClick}
                style={{ backgroundColor: '#FFa800' }}
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
      {showConfirm && <div>Cuenta por Pagar agregada con éxito</div>}
    </>
  );
};

export default AgregarCuentaPorPagar;
