import React, { useState, useEffect } from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Selection, Inject, Edit, Toolbar, Sort, Filter } from '@syncfusion/ej2-react-grids';
import { cotizacionData, customersGrid } from '../data/dummy';
import { Cotizacion, DetalleCotizacion, Header } from '../components';
import { Tabs, Tab } from '@mui/material';
import moment from 'moment'; // Importa moment.js para manejar fechas

const TabWithIcon = ({ color, label }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <span 
      style={{
        backgroundColor: color,
        borderRadius: '50%',
        width: '8px',
        height: '8px',
        marginRight: '8px'
      }}
    />
    {label}
  </div>
);

const Cotizaciones = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [filteredData, setFilteredData] = useState(cotizacionData);
  const [cotizacionVisible, setCotizacionVisible] = useState(false);
  const [detallecotizacionVisible, setdetalleCotizacionVisible] = useState(false);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    filterData(selectedTab);
  }, [selectedTab]);

  const filterData = (tabIndex) => {
    let newData = cotizacionData;

    switch (tabIndex) {
      case 0:
        newData = cotizacionData.filter(item => item.Status === 'Pendiente');
        break;
      case 1:
        newData = cotizacionData.filter(item => item.Status === 'Aprobado');
        break;
      case 2:
        newData = cotizacionData.filter(item => item.Status === 'Cancelado');
        break;
      case 3:
        newData = cotizacionData;
        break;
      default:
        newData = cotizacionData.filter(item => item.Status === 'Pendiente');
    }
    
    setFilteredData(newData);
  };

  const handleNewCotizacionClick = () => {
    setCotizacionVisible(true); // Mostrar el formulario de nueva cotización
  };

  const handleRowDoubleClick = () => {
    setdetalleCotizacionVisible(true); // Mostrar el formulario de cotización al hacer doble clic en una fila
  };

  const isOverdue = (date, status) => {
    const twoWeeksAgo = moment().subtract(14, 'days');
    const recordDate = moment(date, 'YYYY-MM-DD');
    return status === 'Pendiente' && recordDate.isBefore(twoWeeksAgo);
  };

  const handleRowDataBound = (args) => {
    if (isOverdue(args.data.ProjectName, args.data.Status)) {
      args.row.style.backgroundColor = '#FFE5E5'; // Resalta la fila en rojo claro
      args.row.style.color = '#FF0000'; // Cambia el color del texto a rojo
    }
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header title="Cotizaciones" />
      
      <div className="flex justify-between items-center">
        <Tabs value={selectedTab} onChange={handleTabChange} aria-label="basic tabs example"
          sx={{
            '.MuiTab-root': {
              '&:hover': {
                color: '#FFa800', // Cambia el color del texto en hover
              },
              '&.Mui-selected': {
                color: '#FFa800', // Cambia el color del texto cuando la pestaña está seleccionada
              },
            },
            '.MuiTabs-indicator': {
              backgroundColor: '#FFa800', // Cambia el color del subrayado en la pestaña activa
            },
          }}
        >
          <Tab label={<TabWithIcon color="#FEC90F" label="Pendiente" />} />
          <Tab label={<TabWithIcon color="#8BE78B" label="Aprobado" />} />
          <Tab label={<TabWithIcon color="red" label="Cancelado" />} />
          <Tab label="Todas las cotizaciones" />
        </Tabs>
        
        <button
          type="button"
          onClick={handleNewCotizacionClick}
          style={{
            backgroundColor: '#1fb519',  // Verde personalizable
            color: 'white',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            padding: '8px 16px',
            borderRadius: '8px',
            fontWeight: 'lighter',
            textTransform: 'none',
            border: 'none', // Elimina cualquier borde predeterminado
            cursor: 'pointer', // Cambia el cursor al pasar sobre el botón
          }}
        >
          + Nueva Cotización
        </button>
      </div> 

      {/* Renderizar formulario de nueva cotización */}
      {cotizacionVisible && (
        <Cotizacion
          onClose={() => setCotizacionVisible(false)} // Pasar la función onClose a Cotizacion
        />
      )}

      {detallecotizacionVisible && (
        <DetalleCotizacion
          onClose={() => setdetalleCotizacionVisible(false)} // Pasar la función onClose a Cotizacion
        />
      )}

      {/* Grilla de cotizaciones existentes */}
      <GridComponent
        dataSource={filteredData}
        enableHover={false}
        allowPaging
        pageSettings={{ pageCount: 5 }}
        selectionSettings={{ persistSelection: true }}
        toolbar={[]}
        editSettings={{ allowDeleting: true, allowEditing: true }}
        allowSorting
        className="mt-4"
        recordDoubleClick={handleRowDoubleClick} // Manejar el doble clic en una fila
        rowDataBound={handleRowDataBound} // Aplica el estilo condicional
      >
        <ColumnsDirective>
          {customersGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
        </ColumnsDirective>
        <Inject services={[Page, Selection, Toolbar, Edit, Sort, Filter]} />
      </GridComponent>
    </div>
  );
};

export default Cotizaciones;
