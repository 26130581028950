import React, { useState } from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Selection, Toolbar, Inject, Sort, Filter, Search, Edit } from '@syncfusion/ej2-react-grids';
import { Header, DetalleFactura, DetalleFacturasPorCobrar, DetalleFacturasPorPagar, AgregarFactura, AgregarCuentaPorCobrar, AgregarCuentaPorPagar } from '../components';
import { Tabs, Tab } from '@mui/material';
import { financialTabs } from '../data/dummy';

// Template para el botón de descarga de PDF
const pdfButtonTemplate = (props) => {
  return (
    <button
      type="button"
      style={{ backgroundColor: '#FFa800', color: 'white' }}
      className="p-2 rounded flex items-center space-x-2"
      onClick={() =>
        alert(`📄 Reporte financiero de ${props.Cliente || props.ID} se está descargando en formato PDF.`)
      }
    >
      <span>Descargar PDF</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5"
        viewBox="0 0 20 20"
        fill="white"
      >
        <path
          fillRule="evenodd"
          d="M10 3a1 1 0 01.832.445l4 6a1 1 0 01-.832 1.555H6a1 1 0 01-.832-1.555l4-6A1 1 0 0110 3zm-3 9v3a1 1 0 001 1h4a1 1 0 001-1v-3h-6z"
          clipRule="evenodd"
        />
      </svg>
    </button>
  );
};

const Finanzas = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [agregarFacturaVisible, setAgregarFacturaVisible] = useState(false);
  const [agregarCuentaPorCobrarVisible, setAgregarCuentaPorCobrarVisible] = useState(false);
  const [agregarCuentaPorPagarVisible, setAgregarCuentaPorPagarVisible] = useState(false);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setSelectedRow(null); // Resetea la fila seleccionada al cambiar de pestaña
  };

  const handleRowDoubleClick = (args) => {
    setSelectedRow(args.rowData);
  };

  const handleAddItem = () => {
    if (selectedTab === 0) {
      setAgregarFacturaVisible(true); // Muestra el componente AgregarFactura al seleccionar la pestaña 0 (facturas)
    } else if (selectedTab === 1) {
      setAgregarCuentaPorCobrarVisible(true); // Muestra el componente AgregarCuentaPorCobrar al seleccionar la pestaña 1 (cuentas por cobrar)
    } else if (selectedTab === 2) {
      setAgregarCuentaPorPagarVisible(true); // Muestra el componente AgregarCuentaPorPagar al seleccionar la pestaña 2 (cuentas por pagar)
    } else {
      alert('Funcionalidad de agregar nuevo item no implementada en este ejemplo.');
    }
  };

  const handleCloseAgregarFactura = () => {
    setAgregarFacturaVisible(false);
  };

  const handleCloseAgregarCuentaPorCobrar = () => {
    setAgregarCuentaPorCobrarVisible(false);
  };

  const handleCloseAgregarCuentaPorPagar = () => {
    setAgregarCuentaPorPagarVisible(false);
  };

  const handleGenerateReport = () => {
    alert('Generando reportes financieros...');
    // Aquí puedes agregar la lógica para generar reportes financieros
  };

  const currentTab = financialTabs[selectedTab];

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header title="Finanzas" />

      <div className="flex justify-between items-center mb-4">
        <Tabs value={selectedTab} onChange={handleTabChange} aria-label="tabs example"
          sx={{
            '.MuiTab-root': {
              '&:hover': {
                color: '#FFa800',
              },
              '&.Mui-selected': {
                color: '#FFa800',
              },
            },
            '.MuiTabs-indicator': {
              backgroundColor: '#FFa800',
            },
          }}
        >
          {financialTabs.map((tab, index) => (
            <Tab key={index} label={tab.label} />
          ))}
        </Tabs>

        {(selectedTab === 0 || selectedTab === 1 || selectedTab === 2) && (
          <button
            type="button"
            onClick={handleAddItem}
            style={{
              backgroundColor: '#1fb519',
              color: 'white',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
              padding: '8px 16px',
              borderRadius: '8px',
              fontWeight: 'lighter',
              textTransform: 'none',
              border: 'none',
              cursor: 'pointer',
            }}
          >
            {selectedTab === 0 && 'Agregar Factura'}
            {selectedTab === 1 && 'Agregar Cuenta por Cobrar'}
            {selectedTab === 2 && 'Agregar Cuenta por Pagar'}
          </button>
        )}

        {selectedTab === 3 && (
          <button
            type="button"
            onClick={handleGenerateReport}
            style={{
              backgroundColor: '#FFa800',
              color: 'white',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
              padding: '8px 16px',
              borderRadius: '8px',
              fontWeight: 'lighter',
              textTransform: 'none',
              border: 'none',
              cursor: 'pointer',
            }}
          >
            Generar Reportes
          </button>
        )}
      </div>

      <GridComponent
        key={selectedTab}
        dataSource={currentTab.data}
        enableHover={false}
        allowPaging
        pageSettings={{ pageCount: 5 }}
        selectionSettings={{ persistSelection: true }}
        toolbar={['Search']}
        editSettings={{ allowDeleting: true, allowEditing: true }}
        allowSorting
        className="mt-4"
        recordDoubleClick={handleRowDoubleClick}
      >
        <ColumnsDirective>
          {currentTab.columns.map((column, index) => (
            <ColumnDirective key={index} {...column} />
          ))}
          {/* Agregar columna para el botón de descarga de PDF solo en Reportes Financieros */}
          {selectedTab === 3 && (
            <ColumnDirective
              headerText="Acciones"
              template={pdfButtonTemplate} // Usar template para el botón PDF
              width="150"
              textAlign="Center"
            />
          )}
        </ColumnsDirective>
        <Inject services={[Page, Selection, Toolbar, Edit, Sort, Filter, Search]} />
      </GridComponent>

      {selectedRow && selectedTab === 0 && (
        <DetalleFactura
          factura={selectedRow}
          onClose={() => setSelectedRow(null)}
        />
      )}

      {selectedRow && selectedTab === 1 && (
        <DetalleFacturasPorCobrar
          facturaPorCobrar={selectedRow}
          onClose={() => setSelectedRow(null)}
        />
      )}

      {selectedRow && selectedTab === 2 && (
        <DetalleFacturasPorPagar
          facturaPorPagar={selectedRow}
          onClose={() => setSelectedRow(null)}
        />
      )}

      {/* Mostrar el componente AgregarFactura si agregarFacturaVisible es true */}
      {agregarFacturaVisible && (
        <AgregarFactura onClose={handleCloseAgregarFactura} />
      )}

      {/* Mostrar el componente AgregarCuentaPorCobrar si agregarCuentaPorCobrarVisible es true */}
      {agregarCuentaPorCobrarVisible && (
        <AgregarCuentaPorCobrar onClose={handleCloseAgregarCuentaPorCobrar} />
      )}

      {/* Mostrar el componente AgregarCuentaPorPagar si agregarCuentaPorPagarVisible es true */}
      {agregarCuentaPorPagarVisible && (
        <AgregarCuentaPorPagar onClose={handleCloseAgregarCuentaPorPagar} />
      )}
    </div>
  );
};

export default Finanzas;
