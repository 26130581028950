import React, { useState, useEffect } from 'react';
import { RiCloseCircleLine } from "react-icons/ri";
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useStateContext } from '../contexts/ContextProvider';
import avatar from '../data/avatar.jpg';
import '../DetalleCotizacion.css'; // Importa los estilos desde el archivo CSS

const NavButton = ({ title, customFunc, icon, color, size = 'xl', padding = 'p-3' }) => (
  <TooltipComponent content={title} position="BottomCenter">
    <button
      type="button"
      onClick={customFunc}
      style={{ color }}
      className={`relative text-${size} rounded-full ${padding} hover:bg-light-gray`}
    >
      {icon}
    </button>
  </TooltipComponent>
);

const DetalleOrdenVenta = ({ onClose, orden }) => {
  const { currentColor } = useStateContext();

  const [cotizacion, setCotizacion] = useState({
    CustomerID: orden.CustomerID,
    CustomerName: orden.CustomerName,
    CustomerEmail: orden.CustomerEmail,
    CustomerImage: avatar,
    ProjectName: orden.ProjectName,
    Status: orden.Estado,
    StatusBg: orden.Estado === 'Asignada' ? '#8BE78B' : '#FFD700',
    Budget: orden.Budget,
    Location: orden.Location,
    Address: orden.Address,
    Cotizacion: 'Instalación',
    RetiroLamina: 'Si',
    CobroDistancia: 'Si: $14.000',
    Dificultad: 'Normal',
    TipoPropiedad: 'Casa',
    Instalador: orden.asignacion || 'Pendiente Asignar',
    Seguimiento: '', // Nuevo campo para seguimiento
    items: [
      { id: 1, code: 'SE20', description: 'Silver Espejada', quantity: 7.2, unitPrice: 32990, total: 251928 },
      { id: 2, code: 'VT10', description: 'Vidrio Termopanel', quantity: 3, unitPrice: 32990, total: 98970 }
    ]
  });

  useEffect(() => {
    setCotizacion(prevCotizacion => ({
      ...prevCotizacion,
      Status: orden.Estado,
      StatusBg: orden.Estado === 'Asignada' ? '#8BE78B' : '#FFD700',
      Instalador: orden.asignacion || 'Pendiente Asignar',
      Seguimiento: orden.Estado === 'Enviada' ? '123456789' : ''
    }));
  }, [orden]);

  const handleInstaladorChange = (e) => {
    const selectedInstalador = e.target.value;
    setCotizacion((prevCotizacion) => ({
      ...prevCotizacion,
      Instalador: selectedInstalador,
      Status: 'Asignada',
      StatusBg: '#8BE78B',
    }));
  };

  const handleSeguimientoChange = (e) => {
    setCotizacion(prevCotizacion => ({
      ...prevCotizacion,
      Seguimiento: e.target.value,
    }));
  };

  // Función para calcular el total de la orden de venta
  const calcularTotal = () => {
    return cotizacion.items.reduce((total, item) => total + item.total, 0);
  };

  return (
    <>
      <div className="bg-half-transparent w-screen fixed nav-item top-0 right-0 h-full flex">
        <div className="responsive-div p-6" style={{ backgroundColor: 'white', borderRadius: '12px', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', width: '50%', maxWidth: 'none', height: '100%', borderLeft: '1px solid #ddd', boxShadow: '-4px 0 10px rgba(0, 0, 0, 0.1)', display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
          <div className="flex justify-between items-center mb-6">
            <p className="font-semibold text-lg" style={{ color: '#FFa800' }}>Detalle Orden Venta</p>
            <NavButton
              title="Cerrar"
              customFunc={onClose}
              color={currentColor}
              icon={<RiCloseCircleLine />}
              size="3xl"
              padding="p-4"
            />
          </div>

          <div className="cotizacion-header">
            <div className="yellow-bar-left"></div>
            <h1 className="cotizacion-title">ORDEN VENTA</h1>
            <div className="yellow-bar-right"></div>
          </div>

          <div className="cliente-info">
            <div className="left-info">
              <p><strong>Cliente:</strong></p>
              <p>{cotizacion.CustomerName}</p>
              <p>{cotizacion.Address}</p>
              <p>{cotizacion.CustomerEmail}</p>
              <p>+56 9 98113260</p>
              <p><strong>Nº Cotización:</strong> {cotizacion.CustomerID}</p>
              <p><strong>Fecha Instalación:</strong> {cotizacion.ProjectName}</p>
              <p><strong>Ubicación:</strong> {cotizacion.Location}</p>
            </div>
            <div className="right-info">
              <p><strong>Estado:</strong> {cotizacion.Status}</p>

              {cotizacion.Status === 'Pendiente Enviar' && (
                <p><strong>N° Seguimiento:</strong> 
                  <input
                    type="text"
                    value={cotizacion.Seguimiento}
                    onChange={handleSeguimientoChange}
                    className="border p-2 rounded-lg"
                    placeholder="Ingresa N° Seguimiento"
                  />
                </p>
              )}

              {cotizacion.Status === 'Asignada' && (
                <p><strong>Instalador:</strong> {cotizacion.Instalador}</p>
              )}

              {cotizacion.Status === 'Enviada' && (
                <p><strong>Código de Seguimiento:</strong> {cotizacion.Seguimiento}</p>
              )}

              {cotizacion.Status === 'Pendiente Asignar' && (
                <p><strong>Instalador: </strong> 
                  <select 
                    value={cotizacion.Instalador} 
                    onChange={handleInstaladorChange}
                    className="border p-2 rounded-lg"
                  >
                    <option value="Pendiente Asignar">Pendiente Asignar</option>
                    <option value="Auto1">Auto 1</option>
                    <option value="Auto2">Auto 2</option>
                  </select>
                </p>
              )}

              {cotizacion.Status !== 'Pendiente Enviar' && cotizacion.Status !== 'Enviada' && (
                <>
                  <p><strong>Tipo Servicio:</strong> {cotizacion.Cotizacion}</p>
                  <p><strong>Retiro Lámina:</strong> {cotizacion.RetiroLamina}</p>
                  <p><strong>Cobro por Distancia:</strong> {cotizacion.CobroDistancia}</p>
                  <p><strong>Dificultad:</strong> {cotizacion.Dificultad}</p>
                  <p><strong>Tipo Propiedad:</strong> {cotizacion.TipoPropiedad}</p>
                </>
              )}

              <p><strong>Valor Total:</strong> {cotizacion.Budget}</p>
            </div>
          </div>

          <table className="items-table">
            <thead>
              <tr>
                <th>Nº</th>
                <th>Código</th>
                <th>Descripción</th>
                <th>Cantidad</th>
                <th>Valor unitario</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {cotizacion.items.map((item, index) => (
                <tr key={index}>
                  <td>{item.id}</td>
                  <td>{item.code}</td>
                  <td>{item.description}</td>
                  <td>{item.quantity} m²</td>
                  <td>${item.unitPrice.toLocaleString()}</td>
                  <td>${item.total.toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Monto total debajo de la tabla */}
          <div className="flex justify-end mt-4">
            <p className="font-semibold text-lg">Total: ${calcularTotal().toLocaleString()}</p>
          </div>

          <div className="flex justify-between mt-4">
            <div>
              <button
                type="button"
                className="bg-yellow-500 text-white p-2 rounded-lg hover:bg-yellow-600 transition duration-200"
              >
                Descargar PDF
              </button>
            </div>
            <div className="flex">
              <button
                type="button"
                className="bg-green-500 text-white p-2 rounded-lg hover:bg-green-600 transition duration-200 mr-4"
                onClick={() => alert('Cambios guardados')}
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetalleOrdenVenta;
