import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { FiSettings } from 'react-icons/fi';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { AiOutlineMenu } from 'react-icons/ai';
import { Footer, Sidebar, ThemeSettings, Cotizacion } from './components';
import { Dashboard, Ordenes, Stock, Clientes, Finanzas, Cotizaciones, Seguridad, Instalacion, Agenda, Login } from './pages';
import './App.css';
import { useStateContext } from './contexts/ContextProvider';

const App = () => {
  const {
    setCurrentColor,
    setCurrentMode,
    currentMode,
    activeMenu,
    currentColor,
    themeSettings,
    setThemeSettings,
    cotizacionVisible,
    setActiveMenu,
  } = useStateContext();

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Recuperar el estado de autenticación desde el localStorage cuando la aplicación se carga/refresca
    const token = localStorage.getItem('access_token');
    if (token) {
      setIsAuthenticated(true); // Si hay token, el usuario está autenticado
    } else {
      setIsAuthenticated(false); // Si no hay token, redirigir al login
    }

    // Configurar el tema al cargar la aplicación
    const currentThemeColor = localStorage.getItem('colorMode');
    const currentThemeMode = localStorage.getItem('themeMode');
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, [setCurrentColor, setCurrentMode]);

  const handleActiveMenu = () => setActiveMenu(!activeMenu);

  return (
    <div className={currentMode === 'Dark' ? 'dark' : ''}>
      {/* SOLO un BrowserRouter envolviendo toda la aplicación */}
      <BrowserRouter>
        {isAuthenticated ? ( // Si el usuario está autenticado, mostrar la app
          <div className="flex relative dark:bg-main-dark-bg overflow-x-hidden">
            <div className="fixed right-4 bottom-4" style={{ zIndex: '1000' }}>
              <TooltipComponent content="Settings" position="Top">
                <button
                  type="button"
                  onClick={() => setThemeSettings(true)}
                  style={{ background: currentColor, borderRadius: '50%' }}
                  className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
                >
                  <FiSettings />
                </button>
              </TooltipComponent>
            </div>

            {activeMenu ? (
              <div className="w-48 md:w-64 lg:w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white">
                <Sidebar setIsAuthenticated={setIsAuthenticated} /> {/* Pasar la función */}
              </div>
            ) : (
              <div className="w-0 dark:bg-secondary-dark-bg">
                <Sidebar setIsAuthenticated={setIsAuthenticated} />
              </div>
            )}

            <div
              className={
                activeMenu
                  ? 'dark:bg-main-dark-bg bg-gray-200 min-h-screen md:ml-72 w-full overflow-x-hidden'
                  : 'bg-main-bg dark:bg-main-dark-bg w-full min-h-screen flex-2 overflow-x-hidden'
              }
            >
              <div className={`fixed top-4 ${activeMenu ? 'left-72' : 'left-4'} z-10`}>
                <TooltipComponent content="Menu" position="BottomCenter">
                  <button
                    type="button"
                    onClick={handleActiveMenu}
                    style={{ color: currentColor }}
                    className="relative text-xl rounded-full p-3 hover:bg-light-gray"
                  >
                    <AiOutlineMenu />
                  </button>
                </TooltipComponent>
              </div>

              {themeSettings && <ThemeSettings />}
              {cotizacionVisible && <Cotizacion />}

              <Routes>
                <Route path="/" element={<Navigate to="/dashboard" />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/cotizaciones" element={<Cotizaciones />} />
                <Route path="/ordenes" element={<Ordenes />} />
                <Route path="/agenda" element={<Agenda />} />
                <Route path="/instalacion" element={<Instalacion />} />
                <Route path="/stock" element={<Stock />} />
                <Route path="/clientes" element={<Clientes />} />
                <Route path="/finanzas" element={<Finanzas />} />
                <Route path="/seguridad" element={<Seguridad />} />
                {/* Redirigir de login a dashboard si ya está autenticado */}
                <Route path="/login" element={isAuthenticated ? <Navigate to="/dashboard" /> : <Login />} />
              </Routes>
              <Footer />
            </div>
          </div>
        ) : (
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Navigate to="/login" />} /> {/* Redirigir a login si no está autenticado */}
          </Routes>
        )}
      </BrowserRouter>
    </div>
  );
};

export default App;
