import React, { useState, useEffect } from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Selection, Inject, Edit, Toolbar, Sort, Filter } from '@syncfusion/ej2-react-grids';
import { InstalacionData, OrdenGrid } from '../data/dummy';
import { Header, DetalleInstalacion } from '../components';
import { Tabs, Tab } from '@mui/material';
import { FaDownload, FaPrint } from 'react-icons/fa';

// Template para mostrar el nombre del cliente
const customerGridImage = (props) => {
  return <span>{props.CustomerName}</span>;
};

// Template para el botón PDF
const actionTemplate = (props) => {
  return (
    <div className="flex items-center space-x-2">
      {/* Botón de descarga */}
      <button
        type="button"
        title="Descargar"
        style={{ backgroundColor: '#FFa800', color: 'white' }}
        className="p-2 rounded flex items-center space-x-2"
        onClick={() =>
          alert(`📄 Cotización de cliente ${props.CustomerID} se está descargando en formato PDF.`)
        }
      >
        <FaDownload className="h-5 w-5" />
      </button>

      {/* Botón de impresión */}
      <button
        type="button"
        title="Imprimir"
        style={{ backgroundColor: '#FFa800', color: 'white' }}
        className="p-2 rounded flex items-center space-x-2"
        onClick={() =>
          alert(`🖨️ Cotización de cliente ${props.CustomerID} se está enviando a la impresora.`)
        }
      >
        <FaPrint className="h-5 w-5" />
      </button>
    </div>
  );
};

const Instalacion = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [filteredData, setFilteredData] = useState(InstalacionData);
  const [DetalleInstalacionVisible, setDetalleInstalacionVisible] = useState(false);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    filterData(selectedTab);
  }, [selectedTab]);

  const filterData = (tabIndex) => {
    let newData = InstalacionData;
  
    switch (tabIndex) {
      case 0:
        newData = InstalacionData.filter(item => item.Estado === 'Pendiente');
        break;
      case 1:
        newData = InstalacionData.filter(item => item.Estado === 'Realizada');
        break;
      case 2:  // Nueva condición para "Visita Terreno"
        newData = InstalacionData.filter(item => item.Estado === 'Visita Terreno');
        break;
      default:
        newData = InstalacionData;
    }
    
    setFilteredData(newData);
  };

  const handleRowDoubleClick = () => {
    setDetalleInstalacionVisible(true); // Mostrar el formulario de detalle al hacer doble clic en una fila
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header title="Mis instalaciones" />
      
      <div className="flex justify-between items-center">
        <Tabs value={selectedTab} onChange={handleTabChange} aria-label="basic tabs example"
          sx={{
            '.MuiTab-root': {
              '&:hover': {
                color: '#FFa800', // Cambia el color del texto en hover
              },
              '&.Mui-selected': {
                color: '#FFa800', // Cambia el color del texto cuando la pestaña está seleccionada
              },
            },
            '.MuiTabs-indicator': {
              backgroundColor: '#FFa800', // Cambia el color del subrayado en la pestaña activa
            },
          }}
        >
          <Tab label="Pendiente" />
          <Tab label="Realizadas" />
          <Tab label="Visita Terreno" /> {/* Nueva pestaña */}
        </Tabs>
      </div>

      {/* Renderizar formulario de detalle de cotización */}
      {DetalleInstalacionVisible && (
        <DetalleInstalacion
          onClose={() => setDetalleInstalacionVisible(false)} // Pasar la función onClose a DetalleInstalacion
        />
      )}

      {/* Grilla de órdenes de venta existentes */}
      <GridComponent
        dataSource={filteredData}
        enableHover={false}
        allowPaging
        pageSettings={{ pageCount: 5 }}
        selectionSettings={{ persistSelection: true }}
        toolbar={[]}
        editSettings={{ allowDeleting: true, allowEditing: true }}
        allowSorting
        className="mt-4"
        recordDoubleClick={handleRowDoubleClick} // Manejar el doble clic en una fila
      >
        <ColumnsDirective>
          {OrdenGrid.map((item, index) => {
            // Excluir la columna "Valor" y "Asignación"
            if (item.field === 'Budget' || item.field === 'asignacion') {
              return null;
            }
            if (item.headerText === 'Nombre') {
              return (
                <ColumnDirective
                  key={index}
                  {...item}
                  template={customerGridImage} // Usar template para mostrar el nombre
                />
              );
            } else if (item.headerText === 'PDF') {
              return (
                <ColumnDirective
                  key={index}
                  {...item}
                  template={actionTemplate} // Usar template para el botón PDF
                />
              );
            } else {
              return (
                <ColumnDirective
                  key={index}
                  {...item}
                />
              );
            }
          })}
        </ColumnsDirective>
        <Inject services={[Page, Selection, Toolbar, Edit, Sort, Filter]} />
      </GridComponent>
    </div>
  );
};

export default Instalacion;
