import React, { useState } from 'react';
import { RiCloseCircleLine } from "react-icons/ri";
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useStateContext } from '../contexts/ContextProvider';
import avatar from '../data/avatar.jpg';
import { FaWaze } from 'react-icons/fa'; 
import { Cotizacion } from '../components';

const DetalleInstalacion = ({ onClose }) => {
  const { currentColor } = useStateContext();
  const [cotizacionVisible, setCotizacionVisible] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]); // Estado para almacenar archivos cargados
  const [orderConfirmed, setOrderConfirmed] = useState(false); // Estado para el checkbox de confirmación de orden
  const [customerContacted, setCustomerContacted] = useState(false); // Estado para el checkbox de contacto con el cliente
  const [confirmationTime, setConfirmationTime] = useState(''); // Estado para almacenar la hora de confirmación de orden
  const [contactTime, setContactTime] = useState(''); // Estado para almacenar la hora de confirmación de contacto

  const [cotizacion, setCotizacion] = useState({
    CustomerID: 1001,
    CustomerName: 'Jorge González',
    CustomerEmail: 'jorge.gonzalez@gmail.com',
    CustomerImage: avatar,
    ProjectName: '2024-01-15',
    Status: 'Pendiente',
    StatusBg: '#FFD700',
    Budget: '$20.000',
    Location: 'Valparaíso',
    Address: 'Avenida Alemania 1200, Valparaíso',
    Cotizacion: 'Instalación',
    RetiroLamina: 'Si',
    CobroDistancia: '$0',
    Dificultad: 'Normal',
    TipoPropiedad: 'Casa',
    HoraLlegada: '',
    HoraSalida: '',
    ImagenesLink: '',
    Notas: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCotizacion((prevCotizacion) => ({
      ...prevCotizacion,
      [name]: value,
    }));
  };

  const handleCerrarInstalacion = () => {
    const { HoraLlegada, HoraSalida } = cotizacion;

    if (!orderConfirmed) {
      alert('Debes confirmar que has recibido y leído la orden de instalación.');
      return;
    }

    if (!customerContacted) {
      alert('Debes confirmar que el cliente ha sido contactado para confirmar la fecha de instalación.');
      return;
    }

    if (!HoraLlegada || !HoraSalida || uploadedFiles.length === 0) {
      alert('Todos los campos son obligatorios: "Hora de llegada", "Hora de salida" y "Cargar imágenes" deben estar completados.');
      return;
    }

    // Si la validación pasa
    alert('Instalación cerrada con éxito');
    onClose();
  };

  const handleCotizarClick = () => {
    setCotizacionVisible(true);
  };

  const handleFileUpload = (event) => {
    const files = Array.from(event.target.files);
    setUploadedFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const handleOrderConfirmation = (e) => {
    const isChecked = e.target.checked;
    setOrderConfirmed(isChecked);

    if (isChecked) {
      const currentTime = new Date().toLocaleTimeString();
      setConfirmationTime(`Orden de instalación leída a las ${currentTime}`);
    } else {
      setConfirmationTime('');
    }
  };

  const handleCustomerContacted = (e) => {
    const isChecked = e.target.checked;
    setCustomerContacted(isChecked);

    if (isChecked) {
      const currentTime = new Date().toLocaleTimeString();
      setContactTime(`Cliente contactado para confirmar la fecha de instalación a las ${currentTime}`);
    } else {
      setContactTime('');
    }
  };

  return (
    <>
      <div className="bg-half-transparent w-screen fixed nav-item top-0 right-0 h-full flex">
        <div className="responsive-div p-6" style={{ backgroundColor: 'white', borderRadius: '12px', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', width: '50%', maxWidth: 'none', height: '100%', borderLeft: '1px solid #ddd', boxShadow: '-4px 0 10px rgba(0, 0, 0, 0.1)', display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
          <div className="flex justify-between items-center mb-6">
            <p className="font-semibold text-lg" style={{ color: '#FFa800' }}>Detalle Instalación</p>
            <button
              type="button"
              onClick={onClose}
              style={{ color: currentColor }}
              className="relative text-3xl rounded-full p-4 hover:bg-light-gray"
            >
              <RiCloseCircleLine />
            </button>
          </div>

          <div className="cotizacion-header">
            <div className="yellow-bar-left"></div>
            <h1 className="cotizacion-title">INSTALACIÓN</h1>
            <div className="yellow-bar-right"></div>
          </div>

          <div className="cliente-info">
            <div className="left-info">
              <p><strong>Cliente:</strong></p>
              <p>{cotizacion.CustomerName}</p>
              <p>{cotizacion.Address}</p>
              <p>{cotizacion.CustomerEmail}</p>
              <p>+56 9 98113260</p>
              <a
                href="https://waze.com/ul?ll=-33.45694,-70.64827&navigate=yes"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center mt-2 bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600 transition duration-200"
              >
                <FaWaze className="mr-2" /> Abrir en Waze
              </a>
            </div>
            <div className="right-info">
              <p><strong>Nº Cotización:</strong> {cotizacion.CustomerID}</p>
              <p><strong>Fecha Instalación:</strong> {cotizacion.ProjectName}</p>
              <p><strong>Ubicación:</strong> {cotizacion.Location}</p>
              <p><strong>Estado:</strong> {cotizacion.Status}</p>
              <p><strong>Tipo Servicio:</strong> {cotizacion.Cotizacion}</p>
              <p><strong>Retiro Lámina:</strong> {cotizacion.RetiroLamina}</p>
              <p><strong>Dificultad:</strong> {cotizacion.Dificultad}</p>
              <p><strong>Tipo Propiedad:</strong> {cotizacion.TipoPropiedad}</p>
            </div>
          </div>

          {/* Confirmación de orden de instalación */}
          <div className="mt-4 mb-6">
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                className="form-checkbox"
                checked={orderConfirmed}
                onChange={handleOrderConfirmation}
                disabled={orderConfirmed} // Deshabilitar el checkbox si ya fue marcado
              />
              <span className="ml-2 text-gray-700">Confirmo que he recibido y leído la orden de instalación</span>
            </label>
            {confirmationTime && (
              <p className="mt-2 text-green-600">{confirmationTime}</p>
            )}
          </div>

          {/* Confirmación de contacto con el cliente */}
          <div className="mt-4 mb-6">
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                className="form-checkbox"
                checked={customerContacted}
                onChange={handleCustomerContacted}
                disabled={customerContacted} // Deshabilitar el checkbox si ya fue marcado
              />
              <span className="ml-2 text-gray-700">Confirmo que el cliente ha sido contactado para confirmar la fecha de instalación</span>
            </label>
            {contactTime && (
              <p className="mt-2 text-green-600">{contactTime}</p>
            )}
          </div>

          {/* Tabla de láminas solares */}
          <table className="items-table">
            <thead>
              <tr>
                <th>Nº</th>
                <th>Código</th>
                <th>Descripción</th>
                <th>Medidas</th>
                <th>Cantidad</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>SE20</td>
                <td>Silver Espejada</td>
                <td>3 x 2 metros</td> {/* Medidas agregadas */}
                <td>7,2 m²</td>
              </tr>
              <tr>
                <td>2</td>
                <td>VT10</td>
                <td>Vidrio Termopanel</td>
                <td>1,8 x 3 metros</td> {/* Medidas agregadas */}
                <td>3 m²</td>
              </tr>
            </tbody>
          </table>

          {/* Formulario para el instalador */}
          <div className="mt-6">
            <h3 className="font-semibold text-lg mb-4">Registros de instalación</h3>
            <div className="mb-4">
              <label className="block text-gray-700">Hora de visita</label>
              <input
                type="time"
                name="HoraLlegada"
                className="w-full p-2 border rounded"
                value={cotizacion.HoraLlegada}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Hora de salida</label>
              <input
                type="time"
                name="HoraSalida"
                className="w-full p-2 border rounded"
                value={cotizacion.HoraSalida}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Cargar imágenes</label>
              <input
                type="file"
                className="w-full p-2 border rounded"
                multiple
                onChange={handleFileUpload}
              />
              <ul className="mt-4">
                {uploadedFiles.map((file, index) => (
                  <li key={index} className="text-gray-700">
                    {file.name}
                  </li>
                ))}
              </ul>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Notas</label>
              <textarea
                name="Notas"
                className="w-full p-2 border rounded"
                rows="4"
                placeholder="Notas adicionales sobre la instalación..."
                value={cotizacion.Notas}
                onChange={handleInputChange}
              ></textarea>
            </div>
          </div>

          <div className="flex justify-between mt-4">
            <button
              type="button"
              className="bg-green-500 text-white p-2 rounded-lg hover:bg-green-600 transition duration-200"
              onClick={handleCerrarInstalacion}
            >
              Cerrar instalación
            </button>

            <button
              type="button"
              className="bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600 transition duration-200"
              onClick={handleCotizarClick}
            >
              Cotizar
            </button>
          </div>
        </div>
      </div>

      {/* Renderizar formulario de nueva cotización */}
      {cotizacionVisible && (
        <Cotizacion
          onClose={() => setCotizacionVisible(false)} // Pasar la función onClose a Cotizacion
        />
      )}
    </>
  );
};

export default DetalleInstalacion;
