import React from 'react';

const CotizacionConfirmacion = ({ onClose }) => {
  return (
    <div className="bg-half-transparent w-screen h-screen fixed nav-item top-0 left-0 flex items-center justify-center">
      <div className="bg-white dark:bg-[#484B52] p-8 rounded-lg w-full max-w-lg mx-auto" style={{ height: '90vh', overflowY: 'auto' }}>
        <div className="mb-4">
          <p className="font-semibold text-lg" style={{ color: '#FFa800' }}>Cotización <span>#89503641</span></p>
          <p className="text-sm text-gray-500">INSTALACIÓN</p>
        </div>

        <div className="mb-4">
          <div className="flex justify-between">
            <p className="text-sm text-gray-500">Fecha de cotización:</p>
            <p className="text-sm">01/08/2024</p>
          </div>
          <div className="flex justify-between">
            <p className="text-sm text-gray-500">Fecha de instalación:</p>
            <p className="text-sm">15/08/2024</p>
          </div>
        </div>

        <div className="mb-4">
          <p className="text-sm text-gray-500">Cotización para:</p>
          <p className="text-sm">Sebastián Valenzuela</p>
          <p className="text-sm text-gray-500">Las Quintas #143, Cerro La Cruz, Valparaíso.</p>
          <p className="text-sm">+56 9 85632101</p>
        </div>

        <div className="mb-4">
          <p className="font-semibold text-gray-700">Items Cotización</p>
          <div className="border rounded-md p-4">
            <table className="w-full text-sm">
              <thead>
                <tr>
                  <th className="text-left text-gray-500">Lámina</th>
                  <th className="text-left text-gray-500">Alto</th>
                  <th className="text-left text-gray-500">Ancho</th>
                  <th className="text-left text-gray-500">M2</th>
                  <th className="text-left text-gray-500">Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Silver Espejada</td>
                  <td>1.20 m</td>
                  <td>3 m</td>
                  <td>3.6</td>
                  <td>$200.000</td>
                </tr>
                <tr>
                  <td>Optivisión</td>
                  <td>2 m</td>
                  <td>3 m</td>
                  <td>6</td>
                  <td>$300.000</td>
                </tr>
              </tbody>
            </table>
            <div className="flex justify-between mt-4">
              <p className="text-sm">Total m2:</p>
              <p className="text-sm">9,6 m2</p>
            </div>
            <div className="flex justify-between mt-2">
              <p className="text-sm">Total cotización:</p>
              <p className="text-sm">$500.000</p>
            </div>
          </div>
        </div>

        <div className="mb-4">
          <div className="flex justify-between">
            <p className="text-sm text-gray-500">Descuento:</p>
            <p className="text-sm">10%</p>
          </div>
          <div className="flex justify-between">
            <p className="text-sm text-gray-500">Tipo de instalación:</p>
            <p className="text-sm">Instalación</p>
          </div>
          <div className="flex justify-between">
            <p className="text-sm text-gray-500">Retiro de láminas:</p>
            <p className="text-sm">No</p>
          </div>
          <div className="flex justify-between">
            <p className="text-sm text-gray-500">Cobro por distancia:</p>
            <p className="text-sm">Sí, $50.000</p>
          </div>
          <div className="flex justify-between">
            <p className="text-sm text-gray-500">Dificultad:</p>
            <p className="text-sm">Normal</p>
          </div>
        </div>

        <div className="mb-4">
          <p className="text-sm text-gray-500">Notas adicionales:</p>
          <p className="text-sm">El cliente solicitó que se instale en horario matutino.</p>
        </div>

        <div className="flex justify-between mt-6">
          <button 
            onClick={onClose} 
            className="bg-gray-300 p-2 rounded text-gray-700"
          >
            Cerrar
          </button>
          <button 
            onClick={onClose} // Este botón puede manejar el envío real
            className="p-2 rounded text-white" 
            style={{ backgroundColor: '#FFa800' }}
          >
            Enviar
          </button>
        </div>
      </div>
    </div>
  );
};

export default CotizacionConfirmacion;
