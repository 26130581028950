import React, { useState } from 'react';
import { RiCloseCircleLine } from "react-icons/ri";
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useStateContext } from '../contexts/ContextProvider';
import CotizacionConfirmacion from './CotizacionConfirmacion';

const NavButton = ({ title, customFunc, icon, color }) => (
  <TooltipComponent content={title} position="BottomCenter">
    <button
      type="button"
      onClick={customFunc}
      style={{ color }}
      className="relative text-xl rounded-full p-3 hover:bg-light-gray"
    >
      {icon}
    </button>
  </TooltipComponent>
);

const Orden = ({ onClose }) => {
  const { currentColor } = useStateContext();
  const [userType, setUserType] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);
  const [userSelected, setUserSelected] = useState(false);
  const [serviceTypeVisible, setServiceTypeVisible] = useState(false);
  const [serviceTypeSelected, setServiceTypeSelected] = useState(false);
  const [showRetiroLaminaCheckbox, setShowRetiroLaminaCheckbox] = useState(false);
  const [showMontoInput, setShowMontoInput] = useState(false);
  const [monto, setMonto] = useState(''); // Estado para manejar el valor del monto
  const [useSolicitanteData, setUseSolicitanteData] = useState(null); // Nuevo estado para manejar el uso de los datos del solicitante
  const [direccionInstalacion, setDireccionInstalacion] = useState(''); // Estado para la dirección de instalación
  const [showDificultadMontoInput, setShowDificultadMontoInput] = useState(false);
  const [dificultadMonto, setDificultadMonto] = useState('');
  const [showOtroTipoPropiedad, setShowOtroTipoPropiedad] = useState(false); // Estado para mostrar el campo de texto para "Otros"
  const [otroTipoPropiedad, setOtroTipoPropiedad] = useState(''); // Estado para manejar el valor de "Otros"
  const [metrosRetirar, setMetrosRetirar] = useState(''); // Nuevo estado para manejar los metros a retirar
  const [showMontoDistanciaInput, setShowMontoDistanciaInput] = useState(false);
  const [selectedService, setSelectedService] = useState(''); // Nuevo estado para el tipo de servicio seleccionado
  

  const [existingUserSearch, setExistingUserSearch] = useState('');
  const [newUserDetails, setNewUserDetails] = useState({
    nombre: '',
    apellidos: '',
    region: '',
    comuna: '',
    direccion: '',
    email: '',
    whatsapp: '',
  });

  const handleEnviarClick = (e) => {
    e.preventDefault();
    setShowConfirm(true);
  };

  const handleUserTypeChange = (type) => {
    setUserType(type);
    setUserSelected(true);
    setServiceTypeVisible(false);
    setServiceTypeSelected(false); // Reinicia la selección del tipo de servicio
    setShowRetiroLaminaCheckbox(false); // Reinicia la visibilidad del checkbox de retiro de lámina
    setExistingUserSearch(''); // Reinicia la búsqueda de usuario existente
    setNewUserDetails({
      nombre: '',
      apellidos: '',
      region: '',
      comuna: '',
      direccion: '',
      email: '',
      whatsapp: '',
    }); // Reinicia los detalles del nuevo usuario
  };

  const handleExistingUserSearchChange = (e) => {
    const value = e.target.value;
    setExistingUserSearch(value);
    if (value.trim() !== '') {
      setServiceTypeVisible(true);
    } else {
      setServiceTypeVisible(false);
    }
  };

  const handleNewUserDetailChange = (e) => {
    const { name, value } = e.target;
    setNewUserDetails({
      ...newUserDetails,
      [name]: value,
    });

    const allFieldsFilled = Object.values({
      ...newUserDetails,
      [name]: value,
    }).every(field => field.trim() !== '');

    setServiceTypeVisible(allFieldsFilled);
  };



  const handleServiceTypeChange = (e) => {
    const selectedService = e.target.value;
    setSelectedService(selectedService); // Actualiza el estado con el servicio seleccionado
    setServiceTypeSelected(true);
    if (selectedService === 'instalacion') {
      setShowRetiroLaminaCheckbox(true);
    } else {
      setShowRetiroLaminaCheckbox(false);
    }
  };


  const handleDistanciaChange = (e) => {
    const selectedOption = e.target.value;
    if (selectedOption === 'Si') {
      setShowMontoDistanciaInput(true);
    } else {
      setShowMontoDistanciaInput(false);
      setMonto(''); // Resetea el monto si se selecciona "No"
    }
  };

  const handleUseSolicitanteDataChange = (e) => {
    const selectedOption = e.target.value;
    setUseSolicitanteData(selectedOption === 'Si');
    if (selectedOption === 'Si') {
      setDireccionInstalacion(newUserDetails.direccion); // Usa la dirección del solicitante
    } else {
      setDireccionInstalacion(''); // Deja la dirección vacía para que el usuario la ingrese
    }
  };

  const handleDificultadChange = (e) => {
    const selectedOption = e.target.value;
    setShowDificultadMontoInput(selectedOption !== 'Normal');
  };

  const handleTipoPropiedadChange = (e) => {
    const selectedOption = e.target.value;
    setShowOtroTipoPropiedad(selectedOption === 'Otros');
  };

  const handleRetiroLaminaChange = (e) => {
    const selectedOption = e.target.value;
    if (selectedOption === 'Si') {
      setMetrosRetirar(''); // Resetea los metros si se selecciona "Sí"
      setShowMontoInput(true);
    } else {
      setMetrosRetirar(''); // Resetea los metros si se selecciona "No"
      setShowMontoInput(false);
    }
  };

  return (
    <>
      <div className="bg-half-transparent w-screen fixed nav-item top-0 right-0">
        <div className="responsive-div" style={{ backgroundColor: 'white' }}>
          <div className="flex justify-between items-center mb-4">
            <p className="font-semibold text-lg" style={{ color: '#FFa800' }}>Nueva Orden de venta</p>
            <NavButton
              title="Cerrar"
              customFunc={onClose}
              color={currentColor}
              icon={<RiCloseCircleLine />}
            />
          </div>

          <div className="mb-4">
            <p className="font-semibold text-lg">Orden de venta</p>
          </div>

          <div
            className="overflow-y-auto"
            style={{
              maxHeight: '80vh',
            }}
          >
            {/* Selección entre nuevo usuario o existente */}
            <div className="mb-4">
              <label className="block text-gray-700">Tipo de Usuario</label>
              <div className="flex space-x-4">
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="userType"
                    value="existente"
                    className="mr-2"
                    checked={userType === 'existente'}
                    onChange={() => handleUserTypeChange('existente')}
                  />
                  Existente
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="userType"
                    value="nuevo"
                    className="mr-2"
                    checked={userType === 'nuevo'}
                    onChange={() => handleUserTypeChange('nuevo')}
                  />
                  Nuevo
                </label>
              </div>
            </div>

            {userSelected && userType === 'existente' && (
              <div className="mb-4">
                <label className="block text-gray-700">Buscar Usuario</label>
                <input
                  type="text"
                  className="w-full md:w-1/2 lg:w-1/2 p-2 border rounded"
                  placeholder="Buscar por nombre o email"
                  value={existingUserSearch}
                  onChange={handleExistingUserSearchChange}
                />
              </div>
            )}

            {userSelected && userType === 'nuevo' && (
              <>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                  <div>
                    <label className="block text-gray-700">Nombre</label>
                    <input
                      type="text"
                      name="nombre"
                      className="w-full p-2 border rounded"
                      placeholder=""
                      value={newUserDetails.nombre}
                      onChange={handleNewUserDetailChange}
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700">Apellidos</label>
                    <input
                      type="text"
                      name="apellidos"
                      className="w-full p-2 border rounded"
                      placeholder=""
                      value={newUserDetails.apellidos}
                      onChange={handleNewUserDetailChange}
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700">Región</label>
                    <input
                      type="text"
                      name="region"
                      className="w-full p-2 border rounded"
                      placeholder="Valparaíso"
                      value={newUserDetails.region}
                      onChange={handleNewUserDetailChange}
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700">Comuna</label>
                    <input
                      type="text"
                      name="comuna"
                      className="w-full p-2 border rounded"
                      placeholder="Valparaíso"
                      value={newUserDetails.comuna}
                      onChange={handleNewUserDetailChange}
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700">Dirección</label>
                    <input
                      type="text"
                      name="direccion"
                      className="w-full p-2 border rounded"
                      placeholder=""
                      value={newUserDetails.direccion}
                      onChange={handleNewUserDetailChange}
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700">Email</label>
                    <input
                      type="email"
                      name="email"
                      className="w-full p-2 border rounded"
                      placeholder="filmsolar@gmail.com"
                      value={newUserDetails.email}
                      onChange={handleNewUserDetailChange}
                    />
                  </div>
                </div>

                <div className="mb-4">
                  <label className="block text-gray-700">WhatsApp o teléfono</label>
                  <input
                    type="text"
                    name="whatsapp"
                    className="w-full md:w-1/2 lg:w-1/2 p-2 border rounded"
                    placeholder="+56 9 12345678"
                    value={newUserDetails.whatsapp}
                    onChange={handleNewUserDetailChange}
                  />
                </div>
              </>
            )}

            {serviceTypeVisible && (
              <div className="mb-4">
                <label className="block text-gray-700">Tipo de servicio</label>
                <div className="flex space-x-4">
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="servicio"
                      value="instalacion"
                      className="mr-2"
                      onChange={handleServiceTypeChange}
                    />
                    Instalación
                  </label>
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="servicio"
                      value="envio"
                      className="mr-2"
                      onChange={handleServiceTypeChange}
                    />
                    Envío de material
                  </label>
                </div>
                <div className="mt-4">
                  <label className="block text-gray-700">Dirección: ¿Utilizar los mismos datos que el solicitante</label>
                  <div className="flex space-x-4">
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="usarDatosSolicitante"
                        value="Si"
                        className="mr-2"
                        checked={useSolicitanteData === true}
                        onChange={handleUseSolicitanteDataChange}
                      />
                      Sí
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="usarDatosSolicitante"
                        value="No"
                        className="mr-2"
                        checked={useSolicitanteData === false}
                        onChange={handleUseSolicitanteDataChange}
                      />
                      No
                    </label>
                  </div>
                </div>
              </div>
            )}

                {useSolicitanteData === false && (
                  <div className="flex space-x-4 mb-4">
                    <input
                      type="text"
                      className="w-full md:w-1/2 lg:w-1200 p-2 border rounded"
                      placeholder="Ingrese la dirección de instalación/Envío/Visita"
                      value={direccionInstalacion}
                      onChange={(e) => setDireccionInstalacion(e.target.value)}
                    />
                  </div>
                )}  

                {useSolicitanteData === true && (
                  <div className="flex space-x-4 mb-4">
                    <input
                      type="text"
                      className="w-full p-2 border rounded"
                      placeholder="Las quintas 143, valparaiso"
                      value={direccionInstalacion}
                      disabled
                    />
                  </div>
                )}
       

            {serviceTypeVisible && showRetiroLaminaCheckbox && (
              <div className="mb-4">

                <div className="flex space-x-4 mb-4">
                  <div className="w-1/2">
                    <label className="block text-gray-700">Dificultad</label>
                    <select className="border p-2 rounded" onChange={handleDificultadChange}>
                      <option value="Normal">Normal</option>
                      <option value="Alzador">Categoría 1</option>
                      <option value="Escalera">Categoría 2</option>
                      <option value="Andamio">Categoría 3</option>
                    </select>
                  </div>
                  {showDificultadMontoInput && (
                    <div className="w-1/2">
                      <label className="block text-gray-700">Monto Dificultad</label>
                      <input
                        type="text"
                        className="border p-2 rounded"
                        placeholder="Monto"
                        value={dificultadMonto}
                        onChange={(e) => setDificultadMonto(e.target.value)}
                      />
                    </div>
                  )}

                </div>

                
                <div className="mb-4">
                <div className="flex space-x-4 mb-4">
                  <div className="w-1/2">
                    <label className="block text-gray-700">¿Requiere retiro de laminas?</label>
                    <div className="flex space-x-4">
                    <label className="flex items-center">
                      <input 
                        type="radio" 
                        name="retiro" 
                        value="Si" 
                        className="mr-2"
                        onChange={handleRetiroLaminaChange} 
                      />
                      Sí
                      {showMontoInput && (  // Cambiado a usar showMontoDistanciaInput
                      <input
                        type="text"
                        className="ml-2 p-2 border rounded"
                        placeholder="m² a retirar"
                        value={monto}
                        onChange={(e) => setMonto(e.target.value)}
                      />
                    )}
                    </label>
                    <label className="flex items-center">
                      <input 
                        type="radio" 
                        name="retiro" 
                        value="No" 
                        className="mr-2"
                        onChange={handleRetiroLaminaChange} 
                      />
                      No
                    </label>
                  </div>
                  </div>
                </div>
                </div>

                <div className="mb-4">
                <label className="block text-gray-700">¿Cobro por distancia?</label>
                <div className="flex space-x-4">
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="distancia"
                      value="Si"
                      className="mr-2"
                      onChange={handleDistanciaChange}
                    />
                    Sí
                    {showMontoDistanciaInput && (  // Cambiado a usar showMontoDistanciaInput
                      <input
                        type="text"
                        className="ml-2 p-2 border rounded"
                        placeholder="Monto"
                        value={monto}
                        onChange={(e) => setMonto(e.target.value)}
                      />
                    )}
                  </label>
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="distancia"
                      value="No"
                      className="mr-2"
                      onChange={handleDistanciaChange}
                    />
                    No
                  </label>
                </div>
                </div>




                <div className="mb-4 flex space-x-4">
                                <div className="w-1/2">
                    <label className="block text-gray-700">Tipo Propiedad</label>
                    <select className="border p-2 rounded" onChange={handleTipoPropiedadChange}>
                      <option value="Casa">Casa</option>
                      <option value="Departamento">Departamento</option>
                      <option value="Oficina">Oficina</option>
                      <option value="Otros">Otros</option>
                    </select>
                  </div>



                  {showOtroTipoPropiedad && (
                    <div className="w-1/2">
                      <label className="block text-gray-700">Nombre tipo de Propiedad</label>
                      <input
                        type="text"
                        className="border p-2 rounded"
                        placeholder="Empresa, hotel, etc."
                        value={otroTipoPropiedad}
                        onChange={(e) => setOtroTipoPropiedad(e.target.value)}
                      />
                    </div>
                  )}

              </div>


              </div>
            )}

              
{serviceTypeSelected && (selectedService === 'instalacion' || selectedService === 'envio') && (
  <>
    <div className="mb-4">
      <p className="font-semibold text-gray-700">Items Orden de venta</p>
      <div className="grid grid-cols-5 gap-4 text-center font-semibold text-gray-700">
        <p>Lámina</p>
        <p>Alto</p>
        <p>Ancho</p>
        <p>M2</p>
        <p>Total</p>
      </div>
      <div className="grid grid-cols-5 gap-4 mb-2">
        <select className="border p-2 rounded">
          <option>Silver Espejada</option>
          <option>Optivisión</option>
          <option>Premium Nova</option>
        </select>
        <input type="text" className="border p-2 rounded text-center" placeholder="1.20 m" />
        <input type="text" className="border p-2 rounded text-center" placeholder="3 m" />
        <input type="text" className="border p-2 rounded text-center" placeholder="3.6" />
        <p className="self-center">$200.000</p>
      </div>
      <div className="grid grid-cols-5 gap-4 mb-2">
        <select className="border p-2 rounded">
          <option>Optivisión</option>
          <option>Silver Espejada</option>
          <option>Premium Nova</option>
        </select>
        <input type="text" className="border p-2 rounded text-center" placeholder="2 m" />
        <input type="text" className="border p-2 rounded text-center" placeholder="3 m" />
        <input type="text" className="border p-2 rounded text-center" placeholder="6" />
        <p className="self-center">$300.000</p>
      </div>
      <button type="button" className="text-yellow-500" style={{ color: '#FFa800' }}>+ Añadir Item</button>
    </div>

    <div className="flex justify-between items-center mb-4">
      <p className="font-semibold text-gray-700">Total Venta</p>
      <p>$500.000</p>
    </div>

    <div className="mb-4 flex items-center">
      <label className="flex items-center">
        <input type="checkbox" className="mr-2" />
        Descuento
      </label>
      <input type="text" className="ml-2 p-2 border rounded w-20" placeholder="10%" />
    </div>
    
    <div className="mb-4 flex space-x-4">
      <div className="w-1/2">
        <label className="block text-gray-700">Método de Pago</label>
        <select className="border p-2 rounded">
          <option value="Debito">Debito</option>
          <option value="Credito">Credito</option>
          <option value="Transferencia">Transferencia bancaria</option>
          <option value="Efectivo">Efectivo</option>
          <option value="Otros">Otro</option>
        </select>
      </div>
    </div>

  </>
  
)}





            <div className="mb-4">
              <label className="block text-gray-700">Añade una nota adicional para el cliente</label>
              <textarea className="w-full p-2 border rounded" rows="3" placeholder="Añade una nota adicional para el cliente"></textarea>
            </div>

            <div className="flex justify-between mt-4">
              <button type="button" className="bg-gray-300 p-2 rounded text-gray-700" onClick={onClose}>Cerrar</button>
              <button type="submit" className="p-2 rounded text-white" onClick={handleEnviarClick} style={{ backgroundColor: '#FFa800' }}>Guardar</button>
            </div>
          </div>
        </div>
      </div>
      {showConfirm && <CotizacionConfirmacion onClose={() => setShowConfirm(false)} />}
    </>
  );
};


export default Orden;
