import React, { useState, useEffect } from 'react';
import { RiCloseCircleLine } from "react-icons/ri";
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import axios from 'axios';
import { useStateContext } from '../contexts/ContextProvider';

const NavButton = ({ title, customFunc, icon, color }) => (
  <TooltipComponent content={title} position="BottomCenter">
    <button
      type="button"
      onClick={customFunc}
      style={{ color }}
      className="relative text-xl rounded-full p-3 hover:bg-light-gray"
    >
      {icon}
    </button>
  </TooltipComponent>
);

const NuevoStock = ({ onClose, onStockAdded }) => {
  const { currentColor } = useStateContext();
  const [tipoProducto, setTipoProducto] = useState('');
  const [formData, setFormData] = useState({
    codigo: '',
    marca: '',
    rendimiento: '',
    nombre: '',
    unidades: '',
    descripcion: '',
    preciom2: '',
    patente: '', // Solo para vehículos
  });

  const [errors, setErrors] = useState({});
  const [canSubmit, setCanSubmit] = useState(false);
  const [isCheckingCode, setIsCheckingCode] = useState(false);

  // Validar si el código o patente ya existe en la base de datos
  const validateCodigoOrPatenteExists = async (value, field) => {
    if (!value) return;
    setIsCheckingCode(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/validar-${field}/?${field}=${value}`);
      if (response.data.exists) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: `${field.charAt(0).toUpperCase() + field.slice(1)} ya existe. Por favor, ingrese uno diferente.`,
        }));
        setCanSubmit(false);
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: null,
        }));
        setCanSubmit(true);
      }
    } catch (error) {
      console.error(`Error verificando el ${field}:`, error);
    } finally {
      setIsCheckingCode(false);
    }
  };

  const handleTipoProductoChange = (e) => {
    setTipoProducto(e.target.value);
    setFormData({
      codigo: '',
      marca: '',
      rendimiento: '',
      nombre: '',
      unidades: '',
      descripcion: '',
      preciom2: '',
      patente: '',
    });
    setErrors({});
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === 'codigo' && tipoProducto !== 'vehiculo') {
      validateCodigoOrPatenteExists(value, 'codigo');
    }
    if (name === 'patente' && tipoProducto === 'vehiculo') {
      validateCodigoOrPatenteExists(value, 'patente');
    }

    if (errors[name]) {
      setErrors({ ...errors, [name]: null });
    }
  };

  const validateField = (name, value) => {
    let errorMsg = null;

    if (name === 'nombre' && !value) {
      errorMsg = 'El nombre es obligatorio.';
    }

    if (name === 'codigo' && tipoProducto !== 'vehiculo' && (!value || value.length < 5)) {
      errorMsg = 'El código debe tener al menos 5 caracteres.';
    }

    if (name === 'rendimiento' && tipoProducto === 'lamina' && (!value || value <= 0)) {
      errorMsg = 'El rendimiento debe ser un valor positivo.';
    }

    if (name === 'preciom2' && tipoProducto === 'lamina' && (!value || value <= 0)) {
      errorMsg = 'El precio por m² debe ser un valor positivo.';
    }

    if (name === 'unidades' && tipoProducto !== 'vehiculo' && (!value || value <= 0)) {
      errorMsg = 'Las unidades deben ser un valor positivo.';
    }

    if (name === 'marca' && tipoProducto === 'herramienta' && !value) {
      errorMsg = 'La marca es obligatoria.';
    }

    if (name === 'patente' && tipoProducto === 'vehiculo' && (!value || value.length < 6)) {
      errorMsg = 'La patente debe tener al menos 6 caracteres.';
    }

    return errorMsg;
  };

  const validateForm = () => {
    const newErrors = {};

    Object.keys(formData).forEach((field) => {
      const errorMsg = validateField(field, formData[field]);
      if (errorMsg) {
        newErrors[field] = errorMsg;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0 && !isCheckingCode;
  };

  useEffect(() => {
    setCanSubmit(validateForm());
  }, [formData, isCheckingCode]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const token = localStorage.getItem('access_token');

      let apiEndpoint = '';
      const payload = {
        nombre: formData.nombre,
        descripcion: formData.descripcion,
      };

      if (tipoProducto === 'lamina') {
        apiEndpoint = `${process.env.REACT_APP_BACKEND_URL}/api/laminas/`;
        payload.codigo = formData.codigo;
        payload.marca = formData.marca;
        payload.rendimiento = formData.rendimiento;
        payload.preciom2 = formData.preciom2;
        payload.unidades = formData.unidades;
      } else if (tipoProducto === 'herramienta') {
        apiEndpoint = `${process.env.REACT_APP_BACKEND_URL}/api/herramientas/`;
        payload.codigo = formData.codigo;
        payload.marca = formData.marca;
        payload.unidades = formData.unidades;
      } else if (tipoProducto === 'vehiculo') {
        apiEndpoint = `${process.env.REACT_APP_BACKEND_URL}/api/vehiculos/`;
        payload.patente = formData.patente;
        payload.modelo = formData.nombre;
      }

      const response = await axios.post(apiEndpoint, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log("Nuevo stock agregado:", response.data);
      onStockAdded();
      onClose();
    } catch (error) {
      // Manejo de errores de código o patente duplicada
      if (error.response && error.response.data) {
        if (error.response.data.codigo) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            codigo: error.response.data.codigo[0],
          }));
        }
        if (error.response.data.patente) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            patente: error.response.data.patente[0],
          }));
        }
      } else {
        console.error("Error al agregar el nuevo stock:", error);
      }
    }
  };

  return (
    <div className="bg-half-transparent w-screen fixed nav-item top-0 right-0">
      <div className="responsive-div" style={{ backgroundColor: 'white', borderRadius: '12px', padding: '24px', maxWidth: '50%', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)' }}>
        <div className="flex justify-between items-center mb-4">
          <p className="font-semibold text-lg" style={{ color: '#FFa800' }}>Nuevo Stock</p>
          <NavButton title="Cerrar" customFunc={onClose} color={currentColor} icon={<RiCloseCircleLine />} />
        </div>

        <div className="mb-4">
          <p className="font-semibold text-lg">Agregar Producto</p>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Tipo de Producto</label>
          <select className="w-full p-2 border rounded" value={tipoProducto} onChange={handleTipoProductoChange}>
            <option value="">Seleccione un tipo</option>
            <option value="lamina">Lámina</option>
            <option value="herramienta">Herramienta</option>
            <option value="vehiculo">Vehículo</option>
          </select>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            {tipoProducto !== 'vehiculo' && (
              <div>
                <label className="block text-gray-700">Código</label>
                <input
                  type="text"
                  name="codigo"
                  value={formData.codigo}
                  onChange={handleChange}
                  className="w-full p-2 border rounded"
                  placeholder="Código"
                />
                {errors.codigo && <p className="text-red-500">{errors.codigo}</p>}
              </div>
            )}

            {tipoProducto === 'lamina' && (
              <>
                <div>
                  <label className="block text-gray-700">Marca</label>
                  <input
                    type="text"
                    name="marca"
                    value={formData.marca}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                    placeholder="Marca"
                  />
                </div>
                <div>
                  <label className="block text-gray-700">Rendimiento (m²)</label>
                  <input
                    type="number"
                    name="rendimiento"
                    value={formData.rendimiento}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                    placeholder="Rendimiento en m²"
                  />
                  {errors.rendimiento && <p className="text-red-500">{errors.rendimiento}</p>}
                </div>
                <div>
                  <label className="block text-gray-700">Precio por m²</label>
                  <input
                    type="number"
                    name="preciom2"
                    value={formData.preciom2}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                    placeholder="Precio por m²"
                  />
                  {errors.preciom2 && <p className="text-red-500">{errors.preciom2}</p>}
                </div>
              </>
            )}

            {tipoProducto === 'herramienta' && (
              <div>
                <label className="block text-gray-700">Marca</label>
                <input
                  type="text"
                  name="marca"
                  value={formData.marca}
                  onChange={handleChange}
                  className="w-full p-2 border rounded"
                  placeholder="Marca de la herramienta"
                />
                {errors.marca && <p className="text-red-500">{errors.marca}</p>}
              </div>
            )}

            {tipoProducto === 'vehiculo' && (
              <div>
                <label className="block text-gray-700">Patente</label>
                <input
                  type="text"
                  name="patente"
                  value={formData.patente}
                  onChange={handleChange}
                  className="w-full p-2 border rounded"
                  placeholder="Patente del vehículo"
                />
                {errors.patente && <p className="text-red-500">{errors.patente}</p>}
              </div>
            )}

            <div>
              <label className="block text-gray-700">Nombre</label>
              <input
                type="text"
                name="nombre"
                value={formData.nombre}
                onChange={handleChange}
                className="w-full p-2 border rounded"
                placeholder={tipoProducto === 'vehiculo' ? 'Modelo del vehículo' : 'Nombre del producto'}
              />
              {errors.nombre && <p className="text-red-500">{errors.nombre}</p>}
            </div>

            {tipoProducto !== 'vehiculo' && (
              <div>
                <label className="block text-gray-700">Unidades</label>
                <input
                  type="number"
                  name="unidades"
                  value={formData.unidades}
                  onChange={handleChange}
                  className="w-full p-2 border rounded"
                  placeholder="Cantidad de unidades"
                />
                {errors.unidades && <p className="text-red-500">{errors.unidades}</p>}
              </div>
            )}

            <div className="col-span-2">
              <label className="block text-gray-700">Descripción</label>
              <textarea
                name="descripcion"
                value={formData.descripcion}
                onChange={handleChange}
                className="w-full p-2 border rounded"
                placeholder="Descripción del producto"
              ></textarea>
            </div>
          </div>

          <div className="flex justify-between mt-4">
            <button type="button" className="bg-gray-300 p-2 rounded text-gray-700" onClick={onClose}>
              Cerrar
            </button>
            <button type="submit" className="p-2 rounded text-white" style={{ backgroundColor: '#FFa800' }} disabled={!canSubmit || isCheckingCode}>
              {isCheckingCode ? 'Validando...' : 'Agregar'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NuevoStock;
