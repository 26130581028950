import React, { useState } from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Selection, Inject, Edit, Toolbar, Sort, Filter } from '@syncfusion/ej2-react-grids';
import { usuariosData, usuariosGrid, rolesData,rolesGrid, permisosData,permisosGrid } from '../data/dummy';
import { Header } from '../components';
import avatar from '../data/avatar.jpg';
import { Tabs, Tab } from '@mui/material'; // <-- Asegúrate de tener estas importaciones

const Seguridad = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header title="Seguridad" />

      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        aria-label="tabs example"
        sx={{
          '.MuiTab-root': {
            '&:hover': {
              color: '#FFa800',
            },
            '&.Mui-selected': {
              color: '#FFa800',
            },
          },
          '.MuiTabs-indicator': {
            backgroundColor: '#FFa800',
          },
        }}
      >
        <Tab label="Editar perfil" />
        <Tab label="Definir Roles" />
        <Tab label="Definir Permisos" />
        <Tab label="Gestión de Usuarios" />
      </Tabs>

      {/* Pestaña Editar Perfil */}
      {selectedTab === 0 && (
        <div>
          <div className="flex flex-col md:flex-row gap-4 items-center mt-6">
            {/* Imagen de perfil */}
            <div className="relative">
              <img
                src={avatar}
                alt="profile"
                className="rounded-full w-36 h-36"
              />
              <button
                type="button"
                className="absolute bottom-2 right-2 bg-yellow-500 p-2 rounded-full"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="w-5 h-5 text-white"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15.232 5.232l3.536 3.536M9 13l6-6m-6 6v6h6M3 20h18"
                  />
                </svg>
              </button>
            </div>

            {/* Formulario de edición de perfil */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700">Nombre</label>
                <input
                  type="text"
                  defaultValue="Sebastian Valenzuela"
                  className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                />
              </div>
              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700">Cargo</label>
                <input
                  type="text"
                  defaultValue="Instalador"
                  className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                />
              </div>
              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700">Email</label>
                <input
                  type="email"
                  defaultValue="sebastian.valenz7@gmail.com"
                  className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                />
              </div>
              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700">Fecha de Nacimiento</label>
                <input
                  type="text"
                  defaultValue="03 Marzo 1991"
                  className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                />
              </div>
              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700">Teléfono</label>
                <input
                  type="text"
                  defaultValue="+56 9 85632101"
                  className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                />
              </div>
              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700">Región</label>
                <input
                  type="text"
                  defaultValue="Valparaíso"
                  className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                />
              </div>
              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700">Comuna</label>
                <input
                  type="text"
                  defaultValue="Valparaíso"
                  className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                />
              </div>
              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700">Dirección</label>
                <input
                  type="text"
                  defaultValue="Las Quintas #143"
                  className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                />
              </div>
            </div>
          </div>

          <div className="mt-6 flex justify-end">
            <button
              type="button"
              className="bg-yellow-500 text-white py-2 px-4 rounded-md"
            >
              Guardar
            </button>
          </div>
        </div>
      )}

      {/* Pestaña Definir Roles */}
      {selectedTab === 1 && (
        <div>
          <Header title="Definir Roles" />
          <div className="mt-6">
            <h2 className="text-lg font-medium text-gray-700 mb-4">Agregar o Modificar Roles</h2>
            <form className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Nombre del Rol</label>
                <input
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                  placeholder="Ej: Administrador, Instalador, etc."
                />
              </div>
              <div className="flex items-end">
                <button
                  type="button"
                  className="bg-yellow-500 text-white py-2 px-4 rounded-md"
                >
                  Guardar Rol
                </button>
              </div>
            </form>

            <div className="mt-8">
              <h3 className="text-md font-medium text-gray-700 mb-4">Roles Existentes</h3>
              <GridComponent
                dataSource={rolesData}
                allowPaging
                pageSettings={{ pageCount: 5 }}
                selectionSettings={{ persistSelection: true }}
                toolbar={[]}
                editSettings={{ allowDeleting: true, allowEditing: true }}
                allowSorting
              >
                <ColumnsDirective>
                  {rolesGrid.map((item, index) => (
                    <ColumnDirective key={index} {...item} />
                  ))}
                </ColumnsDirective>
                <Inject services={[Page, Selection, Toolbar, Edit, Sort, Filter]} />
              </GridComponent>
            </div>
          </div>
        </div>
      )}

      {/* Pestaña Definir Permisos */}
      {selectedTab === 2 && (
        <div>
          <Header title="Definir Permisos" />
          <div className="mt-6">
            <h2 className="text-lg font-medium text-gray-700 mb-4">Asignar Permisos a Roles</h2>
            <form className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Seleccionar Rol</label>
                <select className="mt-1 block w-full border border-gray-300 rounded-md p-2">
                  {rolesData.map((rol) => (
                    <option key={rol.ID} value={rol.Nombre}>{rol.Nombre}</option>
                  ))}
                </select>
              </div>
            </form>

            <div className="mt-8">
              <h3 className="text-md font-medium text-gray-700 mb-4">Permisos Disponibles</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {permisosData.map((permiso) => (
                  <label key={permiso.ID} className="inline-flex items-center">
                    <input
                      type="checkbox"
                      value={permiso.Nombre}
                      className="form-checkbox h-5 w-5 text-yellow-600"
                    />
                    <span className="ml-2 text-gray-700">{permiso.Nombre}</span>
                  </label>
                ))}
              </div>

              <div className="mt-6 flex justify-end">
                <button
                  type="button"
                  className="bg-yellow-500 text-white py-2 px-4 rounded-md"
                >
                  Guardar Permisos
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Pestaña Gestión de Usuarios */}
      {selectedTab === 3 && (
        <div>
          <Header title="Gestión de Usuarios" />
          <div className="mt-6 flex justify-end">
            <button
              type="button"
              className="bg-yellow-500 text-white py-2 px-4 rounded-md"
            >
              Agregar Usuario
            </button>
          </div>
          <GridComponent
            dataSource={usuariosData}
            allowPaging
            pageSettings={{ pageCount: 5 }}
            selectionSettings={{ persistSelection: true }}
            toolbar={[]}
            editSettings={{ allowDeleting: true, allowEditing: true }}
            allowSorting
            className="mt-4"
          >
            <ColumnsDirective>
              {usuariosGrid.map((item, index) => (
                <ColumnDirective key={index} {...item} />
              ))}
            </ColumnsDirective>
            <Inject services={[Page, Selection, Toolbar, Edit, Sort, Filter]} />
          </GridComponent>
        </div>
      )}
    </div>
  );
};

export default Seguridad;
