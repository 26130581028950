import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styles from '../Login.module.css'; // Importamos los estilos como un módulo

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/token/`, {
        username,
        password,
      });
  
      // Guardar los tokens en localStorage
      localStorage.setItem('access_token', response.data.access);
      localStorage.setItem('refresh_token', response.data.refresh);
  
      // Configurar Axios para incluir el token de autorización en futuras solicitudes
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access;
  
      // Redirigir forzando la recarga de la página
      window.location.href = '/dashboard';
    } catch (error) {
      setError('Login failed, please check your credentials');
    }
  };

  const backgroundImage = `${process.env.PUBLIC_URL}/fondo_login.jpg`; // Referencia la imagen desde public
  const logoImage = `${process.env.PUBLIC_URL}/logo.png`; // Agrega la referencia a la imagen del logo

  return (
    <div className={styles.loginPage} style={{ backgroundImage: `url(${backgroundImage})` }}>
      {/* Imagen flotante del logo, fuera del container pero en la misma página */}
      <img src={logoImage} alt="Logo" className={styles.logoImage} />
      <div className={styles.loginContainer}>
        <h2>Inicia sesión para ingresar</h2>
        {error && <p className={styles.errorMessage}>{error}</p>}
        <form onSubmit={handleSubmit}>
          <div>
            <input
              type="text"
              className={styles.inputField}
              placeholder="Email"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div>
            <input
              type="password"
              className={styles.inputField}
              placeholder="Contraseña"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <a href="#" className={styles.loginLink} style={{ color: 'red', textAlign: 'right', display: 'block', marginBottom: '1rem' }}>
            Olvidé mi contraseña
          </a>
          <button type="submit" className={styles.submitButton}>Iniciar sesión</button>
        </form>
      </div>
    </div>
  );
};

export default Login;
