import React, { useState } from 'react';
import { RiCloseCircleLine } from "react-icons/ri";
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useStateContext } from '../contexts/ContextProvider';
import avatar from '../data/avatar.jpg';
import '../DetalleCotizacion.css'; // Importa los estilos desde el archivo CSS

const NavButton = ({ title, customFunc, icon, color, size = 'xl', padding = 'p-3' }) => (
  <TooltipComponent content={title} position="BottomCenter">
    <button
      type="button"
      onClick={customFunc}
      style={{ color }}
      className={`relative text-${size} rounded-full ${padding} hover:bg-light-gray`}
    >
      {icon}
    </button>
  </TooltipComponent>
);

const DetalleCotizacion = ({ onClose }) => {
  const { currentColor } = useStateContext();

  const [cotizacion, setCotizacion] = useState({
    CustomerID: 1001,
    CustomerName: 'Jorge González',
    CustomerEmail: 'jorge.gonzalez@gmail.com',
    CustomerImage: avatar,
    ProjectName: '2024-01-15',
    Status: 'Pendiente',
    StatusBg: '#FFD700',
    Budget: '$20.000',
    Location: 'Valparaíso',
    Address: 'Avenida Alemania 1200, Valparaíso',
    Cotizacion: 'Instalación',
    RetiroLamina: 'Si',
    CobroDistancia: '$0',
    Dificultad: 'Normal',
    TipoPropiedad: 'Casa',
    items: [
      { id: 1, code: 'SE20', description: 'Silver Espejada', quantity: 7.2, unitPrice: 32990, total: 251928 },
      { id: 2, code: 'VT10', description: 'Vidrio Termopanel', quantity: 3, unitPrice: 32990, total: 98970 }
    ]
  });

  // Función para calcular el total de la cotización
  const calcularTotal = () => {
    return cotizacion.items.reduce((total, item) => total + item.total, 0);
  };

  const handleStatusChange = (newStatus) => {
    let statusBg = '';

    if (newStatus === 'Aprobado') {
      statusBg = '#8BE78B';
    } else if (newStatus === 'Cancelado') {
      statusBg = '#FF5C5C';
    } else {
      statusBg = '#FFD700';
    }

    setCotizacion((prevCotizacion) => ({
      ...prevCotizacion,
      Status: newStatus,
      StatusBg: statusBg,
    }));
  };

  return (
    <>
      <div className="bg-half-transparent w-screen fixed nav-item top-0 right-0 h-full flex">
        <div className="responsive-div p-6" style={{ backgroundColor: 'white', borderRadius: '12px', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', width: '50%', maxWidth: 'none', height: '100%', borderLeft: '1px solid #ddd', boxShadow: '-4px 0 10px rgba(0, 0, 0, 0.1)', display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
          <div className="flex justify-between items-center mb-6">
            <p className="font-semibold text-lg" style={{ color: '#FFa800' }}>Detalle Cotización</p>
            <NavButton
              title="Cerrar"
              customFunc={onClose}
              color={currentColor}
              icon={<RiCloseCircleLine />}
              size="3xl"  // Aumenta el tamaño del ícono
              padding="p-4"  // Aumenta el padding del botón
            />
          </div>

          <div className="cotizacion-header">
            <div className="yellow-bar-left"></div>
            <h1 className="cotizacion-title">COTIZACIÓN</h1>
            <div className="yellow-bar-right"></div>
          </div>

          <div className="cliente-info">
            <div className="left-info">
              <p><strong>Cliente:</strong></p>
              <p>{cotizacion.CustomerName}</p>
              <p>{cotizacion.Address}</p>
              <p>{cotizacion.CustomerEmail}</p>
              <p>+56 9 98113260</p>
              <p><strong>Nº Cotización:</strong> {cotizacion.CustomerID}</p>
            </div>
            <div className="right-info">
              
              <p><strong>Estado:</strong> {cotizacion.Status}</p>
              <p><strong>Tipo Servicio:</strong> {cotizacion.Cotizacion}</p>
              <p><strong>Retiro Lámina:</strong> {cotizacion.RetiroLamina}</p>
              <p><strong>Cobro por Distancia:</strong> {cotizacion.CobroDistancia}</p>
              <p><strong>Dificultad:</strong> {cotizacion.Dificultad}</p>
              <p><strong>Tipo Propiedad:</strong> {cotizacion.TipoPropiedad}</p>
              <p><strong>Valor Total:</strong> {cotizacion.Budget}</p>
            </div>
          </div>

          <table className="items-table">
            <thead>
              <tr>
                <th>Nº</th>
                <th>Código</th>
                <th>Descripción</th>
                <th>Cantidad</th>
                <th>Valor unitario</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {cotizacion.items.map((item, index) => (
                <tr key={index}>
                  <td>{item.id}</td>
                  <td>{item.code}</td>
                  <td>{item.description}</td>
                  <td>{item.quantity} m²</td>
                  <td>${item.unitPrice.toLocaleString()}</td>
                  <td>${item.total.toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Monto total debajo de la tabla */}
          <div className="flex justify-end mt-4">
            <p className="font-semibold text-lg">Total: ${calcularTotal().toLocaleString()}</p>
          </div>

          <div className="flex justify-between mt-4">
            <div>
              <button
                type="button"
                className="bg-yellow-500 text-white p-2 rounded-lg hover:bg-yellow-600 transition duration-200"
              >
                Descargar PDF
              </button>
            </div>
            <div className="flex">
              <button
                type="button"
                className="bg-green-500 text-white p-2 rounded-lg hover:bg-green-600 transition duration-200 mr-4"
                onClick={() => handleStatusChange('Aprobado')}
              >
                Aprobar
              </button>
              <button
                type="button"
                className="bg-red-500 text-white p-2 rounded-lg hover:bg-red-600 transition duration-200"
                onClick={() => handleStatusChange('Cancelado')}
              >
                Rechazar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetalleCotizacion;
