import React, { useState } from 'react';
import { RiCloseCircleLine } from "react-icons/ri";
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useStateContext } from '../contexts/ContextProvider';

const NavButton = ({ title, customFunc, icon, color }) => (
  <TooltipComponent content={title} position="BottomCenter">
    <button
      type="button"
      onClick={customFunc}
      style={{ color }}
      className="relative text-xl rounded-full p-3 hover:bg-light-gray"
    >
      {icon}
    </button>
  </TooltipComponent>
);

const AgregarCuentaPorCobrar = ({ onClose }) => {
  const { currentColor } = useStateContext();
  const [showConfirm, setShowConfirm] = useState(false);

  const [cuentaPorCobrarDetails, setCuentaPorCobrarDetails] = useState({
    cliente: '',
    monto: '',
    fecha: '',
    estado: 'Pendiente',
    idCotizacion: '',
    notas: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCuentaPorCobrarDetails({ ...cuentaPorCobrarDetails, [name]: value });
  };

  const handleGuardarClick = (e) => {
    e.preventDefault();
    setShowConfirm(true);
    // Aquí puedes agregar la lógica para guardar la nueva cuenta por cobrar
  };

  return (
    <>
      <div className="bg-half-transparent w-screen fixed nav-item top-0 right-0">
        <div className="responsive-div" style={{ backgroundColor: 'white' }}>
          <div className="flex justify-between items-center mb-4">
            <p className="font-semibold text-lg" style={{ color: '#FFa800' }}>Nueva Cuenta por Cobrar</p>
            <NavButton
              title="Cerrar"
              customFunc={onClose}
              color={currentColor}
              icon={<RiCloseCircleLine />}
            />
          </div>

          <div className="mb-4">
            <p className="font-semibold text-lg">Detalles de la Cuenta por Cobrar</p>
          </div>

          <div
            className="overflow-y-auto"
            style={{
              maxHeight: '80vh',
            }}
          >
            {/* Formulario para ingresar los detalles de la cuenta por cobrar */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
              <div>
                <label className="block text-gray-700">Cliente</label>
                <input
                  type="text"
                  name="cliente"
                  className="w-full p-2 border rounded"
                  placeholder="Nombre del Cliente"
                  value={cuentaPorCobrarDetails.cliente}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className="block text-gray-700">Monto</label>
                <input
                  type="text"
                  name="monto"
                  className="w-full p-2 border rounded"
                  placeholder="Monto de la Cuenta por Cobrar"
                  value={cuentaPorCobrarDetails.monto}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className="block text-gray-700">Fecha</label>
                <input
                  type="date"
                  name="fecha"
                  className="w-full p-2 border rounded"
                  value={cuentaPorCobrarDetails.fecha}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className="block text-gray-700">Estado</label>
                <select
                  name="estado"
                  className="w-full p-2 border rounded"
                  value={cuentaPorCobrarDetails.estado}
                  onChange={handleInputChange}
                >
                  <option value="Pendiente">Pendiente</option>
                  <option value="Cobrado">Cobrado</option>
                </select>
              </div>
              <div>
                <label className="block text-gray-700">ID Cotización</label>
                <input
                  type="text"
                  name="idCotizacion"
                  className="w-full p-2 border rounded"
                  placeholder="ID de la Cotización"
                  value={cuentaPorCobrarDetails.idCotizacion}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-span-2">
                <label className="block text-gray-700">Notas</label>
                <textarea
                  name="notas"
                  className="w-full p-2 border rounded"
                  rows="3"
                  placeholder="Notas adicionales"
                  value={cuentaPorCobrarDetails.notas}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="flex justify-between mt-4">
              <button
                type="button"
                className="bg-gray-300 p-2 rounded text-gray-700"
                onClick={onClose}
              >
                Cerrar
              </button>
              <button
                type="submit"
                className="p-2 rounded text-white"
                onClick={handleGuardarClick}
                style={{ backgroundColor: '#FFa800' }}
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
      {showConfirm && <div>Cuenta por Cobrar agregada con éxito</div>}
    </>
  );
};

export default AgregarCuentaPorCobrar;
