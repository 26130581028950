import React, { useState, useEffect } from 'react';
import { RiCloseCircleLine } from "react-icons/ri";
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useStateContext } from '../contexts/ContextProvider';
import '../DetalleCotizacion.css';

const NavButton = ({ title, customFunc, icon, color, size = 'xl', padding = 'p-3' }) => (
  <TooltipComponent content={title} position="BottomCenter">
    <button
      type="button"
      onClick={customFunc}
      style={{ color }}
      className={`relative text-${size} rounded-full ${padding} hover:bg-light-gray`}
    >
      {icon}
    </button>
  </TooltipComponent>
);

const DetalleFacturasPorPagar = ({ onClose, facturaPorPagar }) => {
  const { currentColor } = useStateContext();
  const [isEditing, setIsEditing] = useState(false);
  const [facturaDetails, setFacturaDetails] = useState({
    idFactura: facturaPorPagar.ID,
    fechaFactura: facturaPorPagar.FechaVencimiento,
    montoTotal: facturaPorPagar.Monto,
    proveedorNombre: facturaPorPagar.Proveedor || 'Proveedor No Registrado',
    estado: facturaPorPagar.Estado || 'Pendiente',
  });

  useEffect(() => {
    setFacturaDetails({
      idFactura: facturaPorPagar.ID,
      fechaFactura: facturaPorPagar.FechaVencimiento,
      montoTotal: facturaPorPagar.Monto,
      proveedorNombre: facturaPorPagar.Proveedor || 'Proveedor No Registrado',
      estado: facturaPorPagar.Estado || 'Pendiente',
    });
  }, [facturaPorPagar]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFacturaDetails({ ...facturaDetails, [name]: value });
  };

  return (
    <>
      <div className="bg-half-transparent w-screen fixed nav-item top-0 right-0 h-full flex">
        <div className="responsive-div p-6" style={{ backgroundColor: 'white', borderRadius: '12px', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', width: '50%', maxWidth: 'none', height: '100%', borderLeft: '1px solid #ddd', boxShadow: '-4px 0 10px rgba(0, 0, 0, 0.1)', display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
          <div className="flex justify-between items-center mb-6">
            <p className="font-semibold text-lg" style={{ color: '#FFa800' }}>Detalle Factura por Pagar</p>
            <NavButton
              title="Cerrar"
              customFunc={onClose}
              color={currentColor}
              icon={<RiCloseCircleLine />}
              size="3xl"
              padding="p-4"
            />
          </div>

          <header>
            <div className="logo">
              <img src="./images/logo.png" alt="Logo" />
            </div>
            <div className="yellow-bar"></div>
          </header>

          <div className="cotizacion-header">
            <div className="yellow-bar-left"></div>
            <h1 className="cotizacion-title">FACTURA POR PAGAR</h1>
            <div className="yellow-bar-right"></div>
          </div>

          <div className="cliente-info">
            <div className="left-info">
              <p><strong>Proveedor:</strong></p>
              <p>{facturaDetails.proveedorNombre}</p>
            </div>
            <div className="right-info">
              <p><strong>ID Factura:</strong> {facturaDetails.idFactura}</p>
              <p><strong>Fecha Factura:</strong></p>
              {isEditing ? (
                <input
                  type="date"
                  name="fechaFactura"
                  value={facturaDetails.fechaFactura}
                  onChange={handleInputChange}
                  className="border p-2 rounded-lg"
                />
              ) : (
                <p>{facturaDetails.fechaFactura}</p>
              )}
              <p><strong>Estado:</strong></p>
              {isEditing ? (
                <input
                  type="text"
                  name="estado"
                  value={facturaDetails.estado}
                  onChange={handleInputChange}
                  className="border p-2 rounded-lg"
                />
              ) : (
                <p>{facturaDetails.estado}</p>
              )}
              <p><strong>Valor Total:</strong></p>
              {isEditing ? (
                <input
                  type="text"
                  name="montoTotal"
                  value={facturaDetails.montoTotal}
                  onChange={handleInputChange}
                  className="border p-2 rounded-lg"
                />
              ) : (
                <p>{facturaDetails.montoTotal}</p>
              )}
            </div>
          </div>

          <div className="flex justify-between mt-4">
            <button
              type="button"
              className="bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600 transition duration-200"
              onClick={handleEditClick}
            >
              Editar
            </button>
            <div className="flex">
              <button
                type="button"
                className="bg-yellow-500 text-white p-2 rounded-lg hover:bg-yellow-600 transition duration-200 mr-4"
              >
                Descargar PDF
              </button>
              <button
                type="button"
                className="bg-green-500 text-white p-2 rounded-lg hover:bg-green-600 transition duration-200"
                onClick={() => alert('Cambios guardados')}
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetalleFacturasPorPagar;
